import React, { useContext, useEffect, useState } from 'react';
import Button from '@Shared/Button/Button';
import GenerateMotionContext from './GenerateMotionContext';

const MotionTypeButtons = ({ motionTypes }) => {
  const { selectedMotion, setSelectedMotion } = useContext(GenerateMotionContext);

  const isActive = (typeToCheck) => {
    if (!selectedMotion) return false;
    return selectedMotion.value === typeToCheck.value;
  };

  return (
    <>
      {motionTypes.map(
        (motionType) =>
          motionType.name && (
            <Button
              key={motionType.value}
              onClick={() => setSelectedMotion(motionType)}
              className={`button--outline cursor-pointer recommendation-button ${
                isActive(motionType) ? 'isActive' : ''
              }`}
            >
              {motionType.name}
            </Button>
          )
      )}
    </>
  );
};

export default MotionTypeButtons;
