import React from 'react';
import { useDropzone } from 'react-dropzone';
import './FileUpload.css';
import { RiFileUploadLine } from 'react-icons/ri';

const FileUpload = ({ width = '100%', height = '100px', onDrop, fileName, isDragDropText }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={`drag-file-upload ${isDragActive ? 'drag-active' : ''}`} style={{ width, height }}>
      <input {...getInputProps()} />
      <div className="drag-file-upload-content">
        <RiFileUploadLine color="#2dc008" className="drag-upload-icon" />
        <span className="drag-upload-text">
          {isDragActive ? (
            'Drop the files here ...'
          ) : (
            <>
              <span className="click-to-upload-text">Click to upload </span>
              <span>or drag and drop</span>
            </>
          )}
        </span>
        {fileName && <span className="uploaded-file-name">{fileName}</span>}
        {isDragDropText && <span className="drag-upload-info">SVG, PNG, JPG or GIF (max. 3MB)</span>}
      </div>
    </div>
  );
};

export default FileUpload;
