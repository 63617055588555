import React, { useEffect, useState } from 'react';

//TODO: Fix typewriter effect
function TypewriterText({ text, speed = 50 }) {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (speed <= 0) {
      setDisplayedText(text);
      return;
    }

    let index = 0;

    const type = () => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        index += 1;
        setTimeout(type, speed);
      }
    };

    type();

    return () => {
      setDisplayedText(''); // Cleanup if needed
    };
  }, [text, speed]);

  return <div>{displayedText}</div>;
}

export default TypewriterText;
