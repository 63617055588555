import React, { useState } from 'react';
import fileIcon from "@icons/file-with-background.svg";
import CustomButton from '@Shared/Button/Button';
import addIcon from "@icons/eye-icon.svg";
import actionIcon from '@icons/more-line.svg';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GrDocumentDownload, GrDocumentUpload } from "react-icons/gr";
import summarizeIcon from "@icons/add-summarize.svg";
import './DocumentItem.css';
import CollapsibleCard from '@Shared/CollapsibleCard/CollapsibleCard';
import TextBlock from '../../../Shared/TextBlock/TextBlock';
import ActionMenu from '@Shared/ActionMenu/ActionMenu';

const DocumentItem = ({ title, actionType, summary, role, uploadFileStatus }) => {
    const [isSummaryVisible, setIsSummaryVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [buttonText, setButtonText] = useState(actionType === "Summary" ? "Summary" : "Summarize");
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSummarizeClick = () => {
        if (buttonText === "Summarize") {
            setIsLoading(true);
            setButtonText("Loading...");
            setTimeout(() => {
                setIsSummaryVisible(true);
                setButtonText("Summary");
                setIsLoading(false);
            }, 1000);
        } else {
            setIsSummaryVisible(prev => !prev);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log("Selected file:", file.name);
        }
    };

    const menuItems = uploadFileStatus
        ? [
            {
                label: 'Download document',
                labelColor: "#344054",
                icon: <GrDocumentDownload />,
                onClick: () => console.log("Download clicked"),
            },
            {
                label: 'Delete document',
                labelColor: "#F2295B",
                icon: <FaRegTrashAlt style={{ color: "red" }} />,
                onClick: () => console.log("Delete clicked"),
            }
        ]
        : [
            {
                label: 'Load from PACER',
                labelColor: "#344054",
                onClick: () => console.log("Load clicked"),
            },
            {
                label: 'Upload from local',
                labelColor: "#344054",
                icon: <GrDocumentUpload />,
                onClick: () => document.getElementById('fileInput').click(),
            }
        ];

    return (
        <>
            {role === "Judge Decision" ? (
                <TextBlock text={title} />
            ) : (
                <div className="document-item-card-file-container">
                    <div className='document-file-left-content'>
                        <img src={fileIcon} alt="no file" className='document-item-card-file-icon' />
                        <div className="document-item-card-text">{title}</div>
                    </div>
                    <div className='document-file-left-content'>
                        <CustomButton
                            className={`document-file-summarize-button ${buttonText === "Summarize" ? "summarize" : ""}`}
                            onClick={handleSummarizeClick}
                        >
                            <img src={isLoading ? addIcon : (buttonText === "Summarize" ? summarizeIcon : addIcon)} alt="" />
                            <span>{buttonText}</span>
                        </CustomButton>
                        <ActionMenu icon={actionIcon} items={menuItems} />
                    </div>
                </div>
            )}
            {isSummaryVisible && summary && <CollapsibleCard title={"Summary"} content={summary} />}
            <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </>
    );
};

export default DocumentItem;
