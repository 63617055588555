import axios from "axios";

const BASE_URL = process.env.REACT_APP_ATTICUS_API_URL

export const logInEmail = async (email, password) => {
  console.log('--------- AUTH ---------');
  console.log('base url', BASE_URL);
  try {
    const response = await axios.post(`${BASE_URL}/auth/login/`, { email, password });
    const emailNotConfirmedErrorMessage = 'Authentication failed: An error occurred (UserNotConfirmedException) when calling the InitiateAuth operation: User is not confirmed.'; // This is the message that is sent from the API when the email has not been confirmed
    console.log('response', response);
    if (response.status === 200) {
      return {
        success: true,
        data: response.data
      }
    }
    if (response.data["non_field_errors"][0] === emailNotConfirmedErrorMessage) {
      console.log('------this is the email not confirmed error message-----');
      return {
        success: false,
        message: "Email not confirmed. Please check your email to confirm."
      }
    }

    if (response.data && response.data.access && response.data.refresh) {
      const accessToken = response.data.access;
      const refreshToken = response.data.refresh;
      const user = response.data.user;
      const accessExpiration = response.data.access_expiration;
      const refreshExpiration = response.data.refresh_expiration;

      // Save the tokens and user details for future use (e.g., in localStorage or state)
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('accessExpiration', accessExpiration);
      localStorage.setItem('refreshExpiration', refreshExpiration);

      console.log("Login successful:", user);

      return {
        success: true,
        data: response.data
      };
    } else {
      return {
        success: false,
        message: "Invalid response structure"
      };
    }
  } catch (error) {
    // Custom error message for email not confirmed
    console.log('this is an error', error.response.data.non_field_errors[0]);
    const emailNotConfirmedErrorMessage = 'Authentication failed: An error occurred (UserNotConfirmedException) when calling the InitiateAuth operation: User is not confirmed.' // This is the error the API sends if the email is not confirmed.
    if (error.response.data.non_field_errors[0] === emailNotConfirmedErrorMessage) {
      console.log('------this is the email not confirmed error message-----');
      return {
        success: false,
        message: "Email not confirmed. Please check your email to confirm."
      }
    } else {
      return {
        success: false,
        message: "Error logging in. Please try again."
      }
    }
  }
}

export const registerEmail = async (email, password1, password2, firstName, lastName) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/registration/`, { email, password1, password2, first_name: firstName, last_name: lastName });
    console.log('response', response);
    return {
      success: true,
      data: response
    }
  } catch (error) {
    console.error('Error registering email:', error);
    let errorMessage = "Error registering email. Please try again.";
    if (error?.response?.data?.password1 && error?.response?.data?.password1[0] === 'This password is too common.') {
      errorMessage = "Password too simple. Please create a more secure password.";
    } else {
      errorMessage = "Error registering email. Please check all values and try again. Make sure your password contains at least one captial letter, one lowercase letter, and one special character."
    }
    return {
      success: false,
      message: errorMessage
    }
  }
}

export const confirmAccount = async (token) => {
  try {
    const URL = `${BASE_URL}/auth/registration/verify-email/`;
    const response = await axios.post(URL, { key: token });
    console.log('response', response);
  } catch (error) {
    console.error('Error confirming account:', error);
    return {
      success: false,
      message: "Error confirming account. Please try again."
    }
  }
}