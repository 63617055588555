import { m } from 'framer-motion';
import { authTypes, userTypes } from '../types';
import * as api from '@lib/apis/auth';
import { message } from 'antd';

export const registerEmail = (email, password) => async (dispatch) => {
  try {
    const response = await api.registerEmail(email, password);
    if (response.success) {
      localStorage.removeItem('access');
      localStorage.removeItem('access_expiration');
      localStorage.removeItem('refresh');
      localStorage.removeItem('refresh_expiration');
      localStorage.removeItem('user');
      localStorage.removeItem('isAuthenticated');
      return {
        success: true,
        message: "Success! Please check your email to confirm."
      }
    }
  } catch (error) {
    console.error('Error registering email:', error);
    return {
      success: false,
      message: "Error registering email. Please try again."
    }
  }
}

export const logInEmail = (email, password) => async (dispatch) => {
  try {
    const response = await api.logInEmail(email, password);
    if (response.success) {
      const data = response.data;
      console.log('data.user')
      console.log(data.user)
      localStorage.setItem('access', data.access);
      localStorage.setItem('access_expiration', data.access_expiration);
      localStorage.setItem('refresh', data.refresh);
      localStorage.setItem('refresh_expiration', data.refresh_expiration);
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('isAuthenticated', true);
      dispatch({
        type: authTypes.LOG_IN, payload: {
          acces: data.access,
          access_expiration: data.access_expiration,
          refresh: data.refresh,
          refresh_expiration: data.refresh_expiration,
          user: data.user,
          isAuthenticated: true,
        }
      });
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        message: response.message,
      };
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}

export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem('access');
    localStorage.removeItem('access_expiration');
    localStorage.removeItem('refresh');
    localStorage.removeItem('refresh_expiration');
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthenticated');
    dispatch({ type: authTypes.LOG_OUT, payload: null });
  } catch (error) {
    console.error('Error logging out:', error);
  }
}

