export const getOpenCases = (casesArray) => {
  return casesArray.filter(c => c.status === 'open');
}

export const getClosedCases = (casesArray) => {
  return casesArray.filter(c => c.status === 'closed');
}

export const titleToAbbreviation = (caseTitle) => {
  const words = caseTitle.trim().split(/\s+/);
  const firstLetters = words.map((word) => {
    if (word === 'v.s') {
      return 'vs';
    } else {
      return word.length <= 2 ? word : word[0];
    }
  });
  return firstLetters.filter(Boolean).join(' ');
}
