import React from "react";
import "./Home.css";
import HeroSection from "../Sections/HeroSection/HeroSecton";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import PartnerSection from "../Sections/PartnerSection/PartnerSection";
import LegalAssistantSection from "../Sections/LegalAssistantSection/LegalAssistantSection";
import LegalBriefSection from "../Sections/LegalBriefSection/LegalBriefSection";
import MeetAtticusSection from "../Sections/MeetAtticusSection/MeetAtticusSection";
import VoiceOfSuccess from "../Sections/VoiceOfSuccess/VoiceOfSuccess";

const Home = () => {
  useDocumentTitle("Home | JuristAI");
  return (
    <div className="home-main-container">
      <HeroSection />
      <PartnerSection />
      <MeetAtticusSection />
      <LegalBriefSection />
      <LegalAssistantSection />
      <VoiceOfSuccess />
    </div>
  );
};

export default Home;
