import React from 'react';
import './LegalTeamMemberProfile.css';
import { useNavigate, useParams } from 'react-router-dom';
import { legalTeamMembersData } from '../legalTeamData';
import eyeIcon from '@icons/eye-black.svg';
import arrowBackIcon from '@icons/arrow-left-black.svg';
import DataTable from '@Shared/DataTable/DataTable';

const LegalTeamMemberProfile = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const user = legalTeamMembersData.find((member) => member.id === userId);

  if (!user) return <div>User not found</div>;

  const { image, name, role, title, email, officeName, assignedCases } = user;

  const headings = ['Case Name', 'ID', 'Date', 'Status', 'Action'];

  const renderRow = (caseItem) => (
    <>
      <td>{caseItem.caseName}</td>
      <td>{caseItem.id}</td>
      <td>{caseItem.date}</td>
      <td>
        <span
          className={`status ${
            caseItem.status === 'O'
              ? 'open'
              : caseItem.status === 'P'
              ? 'pending'
              : 'closed'
          }`}
        >
          {caseItem.status === 'O'
            ? 'Open'
            : caseItem.status === 'P'
            ? 'Pending'
            : 'Closed'}
        </span>
      </td>
      <td>
        <img className="action-icon" src={eyeIcon} alt="View" />
      </td>
    </>
  );

  return (
    <div className="legal-team-main-member-container">
      <img
        src={arrowBackIcon}
        alt=""
        className="legal-team-member-back-btn"
        onClick={() => navigate(-1)}
      />
      <div className="legal-team-member-container">
        <div className="legal-team-member-profile">
          <img
            src={image}
            alt={`${name}'s profile`}
            className="legal-team-member-profile-image"
          />
          <div className="profile-info">
            <div>
              <p className="legal-team-member-name">{name}</p>
              <p className="legal-team-member-role">
                {role} <span /> {title}
              </p>
            </div>

            <div className="profile-information-sub-container">
              <p className="profile-info-heading">Email address</p>
              <p className="profile-info-value">{email}</p>
            </div>

            <div className="profile-information-sub-container">
              <p className="profile-info-heading">Office/Firm Name</p>
              <p className="profile-info-value">{officeName}</p>
            </div>
          </div>
        </div>

        <div className="assigned-cases">
          <h2>Assigned Cases ({assignedCases?.length ?? 0})</h2>
          <DataTable
            headings={headings}
            data={assignedCases}
            renderRow={renderRow}
          />
        </div>
      </div>
    </div>
  );
};

export default LegalTeamMemberProfile;
