import React from "react";
import './DashboardPannel.css'
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRightGreen } from '../../../../../../assets/images/atticus/icons/arrow-right-green.svg';

const DashboardPannel = ({children, title, subtitle, linkText, linkAnchor}) => {
  return (
    <div className="dashboard-pannel">
      <div className="dashboard-pannel-content">
        {children}
      </div>
      <p className="dashboard-pannel-title">{title}</p>
      <p className="dashboard-pannel-subtitle">{subtitle}</p>
      <Link to={linkAnchor} className="dashboard-pannel-link">
        {linkText}
        <ArrowRightGreen/>
      </Link>
    </div>
  );
}

export default DashboardPannel;
