import React, { useState, useEffect } from 'react';
import './CaseOverview.css';
import Case from '../Case/Case';
import LastDocketEntry from '../LastDocketEntry/LastDocketEntry';
import CaseLegalTeam from '../CaseLegalTeam/CaseLegalTeam';
import Loader from '@Shared/Loader/Loader';
import { useOutletContext } from 'react-router-dom';

const CaseOverview = () => {
  const { selectedCase } = useOutletContext();
  const [loading, setLoading] = useState(false);

  if (loading || !selectedCase) {
    return <Loader />;
  }

  return (
    <main className="case-overview-page">
      <Case />
      <LastDocketEntry />
      <CaseLegalTeam />
    </main>
  );
};

export default CaseOverview;
