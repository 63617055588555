import React, { useContext } from 'react';
import ChatContext from '../../ChatContext';
import './FeatureCard.css';
import { Link } from 'react-router-dom';

const FeatureCard = ({ icon, title, description, workflow }) => {
  const { setWorkflow } = useContext(ChatContext);

  return (
    <Link to={`/chat/${workflow}`}>
      <div className="chat-feature-card cursor-pointer">
        <div className="chat-feature-heading">
          {' '}
          <img src={icon} alt={`${title} icon`} className="chat-feature-icon" /> <h3>{title}</h3>
        </div>
        <div className="chat-feature-content">
          <p>{description}</p>
        </div>
      </div>
    </Link>
  );
};

export default FeatureCard;
