import React, { useState } from 'react';
import Button from '../Button/Button';
import uploadFileIcon from '@assets/images/atticus/icons/file-upload-fill.svg';
import './Docket.css';
import Drawer from '../Drawer/Drawer';
import DocketCriminalCase from '@pages/Dashboard/Atticus/Cases/CasesShow/DocketUpload/DocketCriminalCase/DocketCriminalCase';
import { Link } from 'react-router-dom';

export const title = 'Criminal No. 1:23-cr-00257-TSC';

const Docket = ({ selectedRow, onSkip, selectedCase }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <div className="docket-modal-main-container rounded-border white-background">
      <div className="docket-modal-header-section">
        <h3>{`${selectedCase?.caseDetails?.caseName}, ${selectedCase?.caseDetails?.courtId}, ${selectedCase?.caseDetails?.dateFiled}`}</h3>

        <div className="docket-modal-all-buttons">
          <Button className="docket-modal-skip-entry-btn" onClick={onSkip}>
            Skip Docket Entry
          </Button>
          <Link to={`${selectedRow.url}`}>
            <Button className="docket-modal-load-pacer-btn">
              Load form Pacer
            </Button>
          </Link>
          <Button
            className="button--primary button-upload-docket-modal"
            onClick={handleDrawerOpen}
          >
            <div className=" button-content-docket-modal">
              <img
                src={uploadFileIcon}
                alt="Upload Icon"
                className="upload-icon"
              />
              <span>Upload Case Document</span>
            </div>
          </Button>
        </div>
      </div>

      <div className="docket-modal-table-section-main">
        <table className="docket-modal-table">
          <tbody>
            <tr>
              <th>Date</th>
              <td>{selectedRow.date}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{selectedRow.filing}</td>
            </tr>
            <tr>
              <th>Entered</th>
              <td>{selectedRow.entered}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Drawer title={title} isOpen={isDrawerOpen} onClose={handleDrawerClose}>
        <div className="drawer-content">
          <DocketCriminalCase
            filing={selectedRow.title}
            enteredDate={selectedRow.date}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default Docket;
