import React, { useState, useRef, useEffect } from 'react';
import './Trial.css';
import Header from '../../Shared/Header/Header';
import RoleSelection from '../Shared/RoleSelection/RoleSelection';
import ChatInputBox from '../../Shared/ChatInputBox/ChatInputBox';
import { trialRolesData } from './trialData';
import MessageCard from '../Shared/MessageCard/MessageCard';
import TrialListing from './TrialListing/TrialListing';

const Trial = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const selectedRoleMessageRef = useRef(null);

  const handleRoleChange = (roleId) => {
    setSelectedRole((prev) => (prev === roleId ? null : roleId));
  };

  const selectedRoleData = trialRolesData.find((role) => role.id === selectedRole);

  useEffect(() => {
    if (selectedRoleData) {
      selectedRoleMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedRoleData]);

  return (
    <div className="chat-profile-container">
      <Header />
      <div className="chat-profile-content">
        <div className="chat-profile-time">Today, 09:00 AM</div>
        <MessageCard text="Welcome to the Trial section! I can assist you in crafting interrogatories, developing trial strategies, or preparing witnesses. What would you like to focus on today?" />
        <RoleSelection roles={trialRolesData} selectedRole={selectedRole} onRoleChange={handleRoleChange} />
        <TrialListing selectedRoleData={selectedRoleData} ref={selectedRoleMessageRef} />
      </div>
      <ChatInputBox />
    </div>
  );
};

export default Trial;
