// socialProfileData.js
import discordIcon from "./../../../assets/images/atticus/social/discord-green.png";
import instagramIcon from "./../../../assets/images/atticus/social/Instagram-green.png";
import linkedinIcon from "./../../../assets/images/atticus/social/linkedin-green.png";
import twitterIcon from "./../../../assets/images/atticus/social/twitter-green.png";

// TODO: Replace stubbed URLs with real URLs.
const profiles = [
  {
    name: "Discord",
    url: "#",
    image: discordIcon,
  },
  {
    name: "Instagram",
    url: "#",
    image: instagramIcon,
  },
  {
    name: "LinkedIn",
    url: "#",
    image: linkedinIcon,
  },
  {
    name: "Twitter",
    url: "#",
    image: twitterIcon,
  },
];

export default profiles;
