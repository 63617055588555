import { caseTypes } from '../types';

const initialState = null;

const casesReducer = (state = initialState, action) => {
  switch (action.type) {
    case caseTypes.GET_CASES:
    case caseTypes.SET_CASES:
      return action.payload;
    case caseTypes.UPDATE_CASES:
      return action.payload;
    default:
      return state;
  }
}

export default casesReducer;
