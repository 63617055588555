import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PacerSearchContainer from '@Shared/PacerSearch/PacerSearchContainer';
import PacerSearchSubmitButton from '@Shared/PacerSearch/PacerSearchSubmitButton';
import { ReactComponent as Plus } from '@icons/plus-black.svg';
import PacerSearchTextInput from '@Shared/PacerSearch/PacerSearchTextInput';
import Drawer from '@Shared/Drawer/Drawer';
import Button from '@Shared/Button/Button';
import { toast } from 'react-toastify';
import AddNewCase from '../../Cases/CasesIndex/AddNewCase/AddNewCase';

const SearchSection = ({ initialValues }) => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formData, setFormData] = useState({});

  const handleFormDataChange = (data) => {
    setFormData(data);
  };

  const handleAdvancedSearch = (data) => {
    searchPacer(data, true);
  };

  const searchPacer = (data, isAdvanced = false) => {
    const searchPayload = {
      searchType: isAdvanced ? 'advanced' : 'basic',
      ...data,
    };
    if (isAdvanced) {
      navigate('/dashboard/cases/search', {
        state: {
          advancedSearch: { data: searchPayload, query: '' },
        },
      });
    } else {
      if (!formData.searchQuery) {
        toast.info('Please enter search query');
        return;
      }
      navigate('/dashboard/cases/search', {
        state: {
          advancedSearch: { data: searchPayload, query: formData?.searchQuery },
        },
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchPacer();
      setOpenDrawer(!openDrawer);
    }
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <main className="dashboard-page cases-page">
      <div className="cases-index-search-form-container">
        <div onKeyDown={handleKeyDown}>
          <PacerSearchContainer onFormDataChange={handleFormDataChange}>
            <PacerSearchTextInput placeholder="Search pacer..." name="searchQuery" />

            <Button className="button--outline cases-index-search-button" onClick={() => searchPacer()}>
              Search
            </Button>
            <PacerSearchSubmitButton onClick={toggleDrawer}>
              <Plus />
              <p>Advanced Search</p>
            </PacerSearchSubmitButton>
          </PacerSearchContainer>
        </div>
      </div>
      <Drawer title={'Search Criminal Case'} isOpen={openDrawer} onClose={toggleDrawer}>
        <AddNewCase onClose={toggleDrawer} onSearch={handleAdvancedSearch} initialValues={initialValues} />
      </Drawer>
    </main>
  );
};

export default SearchSection;
