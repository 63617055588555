import React, { useState } from "react";
import "./TextField.css";
import eyeIcon from "./../../../assets/images/atticus/icons/eye.png";

const TextField = ({ placeholder, onChange, disabled = false, style, onClick, name, className = "", type = "text", value }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const inputType = type === "password" && showPassword ? "text" : type;

  return (
    <div className={`text-field-wrapper ${className}`}>
      <input placeholder={placeholder ?? ""} onChange={onChange} name={name} disabled={disabled} style={style} className="text-field" type={inputType} value={value} />
      {type === "password" && <img src={eyeIcon} alt="Toggle visibility" className="eye-icon" onClick={handleTogglePassword} />}
    </div>
  );
};

export default TextField;
