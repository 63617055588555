import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import './Case.css';
import CollapsibleCard from '@Shared/CollapsibleCard/CollapsibleCard';
import { summarizeCase } from '@lib/apis/pacer-api';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';

const status = 'O';

const Case = () => {
  const { isRecommendationsFetched, selectedCase } = useOutletContext();
  const [caseSummary, setCaseSummary] = useState(null);
  const [loading, setLoading] = useState(false);

  //TODO:  Replace this with current logged in userId once addCase if implemented
  const userId = '84083408-a051-70d1-f910-749d49645793';
  //TODO:  Replace this with current logged user info once addCase if implemented
  const appId = '1';

  const motionTypes = JSON.parse(localStorage.getItem('motionTypes'));

  useEffect(() => {
    const savedSummary = localStorage.getItem('caseSummary');
    if (savedSummary) {
      setCaseSummary(savedSummary);
    } else if (motionTypes && motionTypes.length > 0) {
      fetchCaseSummary();
    } else {
      setCaseSummary(null);
    }
  }, [motionTypes, isRecommendationsFetched, selectedCase]);

  const fetchCaseSummary = async () => {
    console.log('fetching case summary');
    setLoading(true);
    try {
      const response = await summarizeCase(userId, selectedCase.caseId, appId);
      const summary = response?.data?.summary;
      if (summary) {
        localStorage.setItem('caseSummary', summary);
        setCaseSummary(summary);
      }
    } catch (error) {
      console.error('Failed to fetch case summary:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefreshCase = () => {
    setCaseSummary(null);
    setLoading(true);
    localStorage.removeItem('caseSummary');
    fetchCaseSummary();
  };

  const paragraphs = caseSummary ? caseSummary.split(/\n\n/) : [];
  const caseSummaryContent = loading ? (
    <p>
      <LoaderSpinner color="#2dc008" />
    </p>
  ) : (
    <div className="case-summary-description">
      {paragraphs.map((para, idx) => (
        <p key={idx}>{para}</p>
      ))}
    </div>
  );

  return (
    <>
      <CollapsibleCard
        title="Case Summary"
        content={caseSummaryContent}
        onRefresh={handleRefreshCase}
      />
      <div className="case-status-card white-background rounded-border">
        <div className="case-status-content-container">
          {' '}
          <div className="case-statis-label-text"> Case Status</div>{' '}
          <span
            className={`case-status ${
              status === 'O' ? 'open' : status === 'P' ? 'pending' : 'closed'
            }`}
          >
            {status === 'O' ? 'Open' : status === 'P' ? 'Pending' : 'Closed'}
          </span>
        </div>
        <div className="case-status-content-container">
          <div className="case-statis-label-text"> Case Filled </div>
          <div className="case-filled-date">Jan 10, 2024</div>{' '}
        </div>
      </div>
    </>
  );
};

export default Case;
