import React, { useState } from 'react';
import './Compare.css';
import Header from '../../Shared/Header/Header';
import FileUpload from '../Shared/FileUpload/FileUpload';
import Button from '@Shared/Button/Button';
import SelectedFile from '../Shared/SelectedFile/SelectedFile';
import ComparisonTable from '../Shared/ComparisonTable/ComparisonTable';
import { compareTableData } from './compareData';

const Compare = () => {
  const [files, setFiles] = useState([null, null]);
  const [showTable, setShowTable] = useState(false);

  const handleDropFile = (index) => (acceptedFiles) => {
    const updatedFiles = [...files];
    updatedFiles[index] = acceptedFiles[0];
    setFiles(updatedFiles);
  };

  const handleDelete = (index) => () => {
    const updatedFiles = [...files];
    updatedFiles[index] = null;

    if (index === 0 && updatedFiles[1]) {
      updatedFiles[0] = updatedFiles[1];
      updatedFiles[1] = null;
    }

    setFiles(updatedFiles);
  };

  const handleGenerateTable = () => {
    setShowTable(true);
  };

  return (
    <div className="chat-profile-container">
      <Header />
      <div className="chat-profile-content">
        <div className="chat-profile-time">Today, 09:00 AM</div>
        <div className="compare-top-heading-container">
          <div>
            <h6>Compare</h6>
            <p>Please upload documents you wish to compare.</p>
          </div>
          <Button
            className={`button--primary generate-table-button ${!files[0] || !files[1] ? 'disabled' : ''}`}
            onClick={!files[0] || !files[1] ? null : handleGenerateTable}
          >
            Generate Table
          </Button>
        </div>
        <div className="drag-file-upload-main-container">
          {!files[0] && <FileUpload onDrop={handleDropFile(0)} isDragDropText={true} />}
          {files[0] && <SelectedFile fileName={files[0].name} onDelete={handleDelete(0)} />}
          {files[0] && !files[1] && <FileUpload onDrop={handleDropFile(1)} height="52px" width="357px" />}
          {files[1] && <SelectedFile fileName={files[1].name} onDelete={handleDelete(1)} />}
        </div>
        {showTable && <ComparisonTable data={compareTableData} heading={'Here’s a side-by-side comparison of the Fourth and Fifth Amendments:'} />}
      </div>
    </div>
  );
};

export default Compare;
