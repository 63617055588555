import React, { useContext } from 'react';
import Button from '@Shared/Button/Button';
import GenerateMotionContext from './GenerateMotionContext';

const GenerateMotionButton = () => {
  const { setShowModal } = useContext(GenerateMotionContext);

  return (
    <Button
      onClick={() => setShowModal(true)}
      className="generate-recommendation-button"
    >
      Generate Motion
    </Button>
  );
};

export default GenerateMotionButton;
