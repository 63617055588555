import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from '@state/actions/authActions';
import './LogOut.css';

const LogOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());
  }, [dispatch]);

  return (
    <div className="logout-page">
      <h1>You have successfully logged out.</h1>
    </div>
  );
};

export default LogOut;
