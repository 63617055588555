import React from 'react';
import { motion } from 'framer-motion';
import './PageNotFound.css';
import ButtonPrimary from '@Shared/ButtonPrimary/ButtonPrimary';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div className="page-not-found-container">
      <div className="page-not-found-content-container">
        <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
          <h1 className="page-not-found-title">404</h1>
        </motion.div>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2, duration: 0.6 }}>
          <h2 className="page-not-found-subtitle">Oops! Page not found.</h2>
          <p className="page-not-found-description">The page you're looking for doesn't exist.</p>
        </motion.div>

        <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.4, duration: 0.6 }}>
          <Link to={'/'}>
            <ButtonPrimary className="button-primary page-not-found-btn">Back To Home</ButtonPrimary>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default PageNotFound;
