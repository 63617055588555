import React from 'react';
const PacerSearchSubmitButton = ({style, onClick, children }) => {

  return (
      <button type="submit" className="pacer-search-submit-button" style={style} onClick={onClick}>
        {children}
      </button>
  );
}

export default PacerSearchSubmitButton;
