/* eslint-disable no-unreachable */
/* eslint-disable no-undef */

import axios from 'axios';
import legalTeams from '../../mocks/legalTeams'; // Assuming you have a similar mock file for legal teams
import { getAuthHeaders } from '../utilities';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

// CREATE
export const createLegalTeam = async (params, token) => {
  // TODO: Replace the next line with the actual API call
  return Object.keys(params).length === 0 ? legalTeams[0] : { ...params, legalTeamId: 99 }; // Mocked response. If given params, return the params plus an id, otherwise return a mock legal team

  try {
    const response = await axios.post(`${BASE_URL}/legalTeams`, params, getAuthHeaders(token));
    const { legalTeam } = response.data;
    return legalTeam;
  } catch (error) {
    console.error('Error creating legal team:', error);
    return error;
  }
}

// READ
export const getLegalTeam = async (id, token) => {
  const mockLegalTeam = legalTeams[0];

  console.log('getLegalTeam', mockLegalTeam);
  return mockLegalTeam // Mocked response. 

  try {
    const response = await axios.get(`${BASE_URL}/legalTeams/${id}`, getAuthHeaders(token));
    const { legalTeam } = response.data;
    return legalTeam;
  } catch (error) {
    console.error('Error getting legal team:', error);
    return error;
  }
}

// UPDATE
export const updateLegalTeam = async (id, params, token) => {
  return Object.keys(params).length === 0 ? legalTeams[0] : { ...legalTeams[0], ...params }; // Mocked response. If given params, return the params plus a legal team info, otherwise return a mock legal team

  try {
    const response = await axios.put(`${BASE_URL}/legalTeams/${id}`, params, getAuthHeaders(token));
    const { legalTeam } = response.data;
    return legalTeam;
  } catch (error) {
    console.error('Error updating legal team:', error);
    return error;
  }
}

// DELETE
export const deleteLegalTeam = async (id, token) => {
  return legalTeams[0]; // Mocked response. Always return the first legal team

  try {
    const response = await axios.delete(`${BASE_URL}/legalTeams/${id}`, getAuthHeaders(token));
    const { legalTeam } = response.data;
    return legalTeam;
  } catch (error) {
    console.error('Error deleting legal team:', error);
    return error;
  }
}

export const addUserToLegalTeam = async (legalTeamId, userId, token) => {
  return { legalTeamId, userId }; // Mocked response. Always return the given legal team id and user id

  try {
    const response = await axios.post(`${BASE_URL}/legalTeams/${legalTeamId}/addUser`, { userId }, getAuthHeaders(token));
    const { legalTeam } = response.data;
    return legalTeam;
  } catch (error) {
    console.error('Error adding user to legal team:', error);
    return error;
  }
}

export const removeUserFromLegalTeam = async (legalTeamId, userId, token) => {
  return { legalTeamId, userId }; // Mocked response. Always return the given legal team id and user id

  try {
    const response = await axios.post(`${BASE_URL}/legalTeams/${legalTeamId}/removeUser`, { userId }, getAuthHeaders(token));
    const { legalTeam } = response.data;
    return legalTeam;
  } catch (error) {
    console.error('Error removing user from legal team:', error);
    return error;
  }
}
