import React from "react";
import UserHeadshot from "./UserHeadshot/UserHeadshot";
import './UserHeadshotContainer.css';

const UserHeadshotContainer = ({userArray, children}) => {
  return (
    <div className="user-headshot-container">
        {userArray.map((user, index) => (
          <UserHeadshot style={{zIndex: userArray.length - index }} key={index} src={user.src} alt={user.alt} link={user.link} />
        ))}
    </div>
  )

}

export default UserHeadshotContainer;
