import React from "react";

const SocialIcon = ({ title, link, imageLink }) => {
  return (
    <a href={link} className="button-disabled">
      <img src={imageLink} alt={`${title} icon`} />
    </a>
  );
};

export default SocialIcon;
