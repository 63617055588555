import React, { useState } from "react";
import "./LegalAssistantSection.css";
import leftImage from "../../../../../assets/images/atticus/legalAssistant/legal-assistant.png";
import closeIcon from "../../../../../assets/images/atticus/icons/close-black-icon.svg";
import addIcon from "./../../../../../assets/images/atticus/icons/eye-icon.svg";
import fileIcon from "./../../../../../assets/images/atticus/icons/file-with-background.svg";
import { legalInsightsDataArray } from "./legalAssistant";
import Button from "../../../../Shared/Button/Button";
import DataApplicationSection from "./DataApplication/DataApplication";

const LegalAssistantSection = () => {
  const [showGeneratingMotion, setShowGeneratingMotion] = useState(true);
  const [showMotionPrompt, setShowMotionPrompt] = useState(true);
  const [motionPrompt, setMotionPrompt] = useState("");

  return (
    <>
      <section className="legal-assistant-section">
        <h2>Atticus is not just a tool. He’s your partner.</h2>
        <div className="legal-assistant-container">
          <div className="legal-assistant-content">
            <div className="legal-assistant-left-image-container">
              <img className="legal-assistant-left-image" src={leftImage} alt="Legal Assistant" />
              <div className="legal-assistant-top-right-card">
                <div className="legal-assistant-file-container">
                  <img src={fileIcon} alt="no file" />
                  <div className="legal-assistant-top-right-card-text ">Motion for Detention</div>
                  <Button className="legal-assistant-summarize-button button-disabled">
                    <img src={addIcon} alt="" /> <span>Summarize</span>
                  </Button>
                </div>
              </div>
              {showGeneratingMotion && (
                <div className="legal-assistant-left-center-card">
                  <div className="data-driven-container">
                    <div className="data-driven-header-container">
                      <h4>Generating Your Motion</h4>
                      <img src={closeIcon} alt="" className="legal-assistant-close-icon" onClick={() => setShowGeneratingMotion(true)} />
                    </div>
                    {legalInsightsDataArray?.map((data, index) => {
                      return (
                        <div className={`data-driven`} key={index}>
                          <img src={data.image} alt="logo" />
                          <span>{data.text}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {showMotionPrompt && (
                <div className="legal-assistant-bottom-right-card">
                  <div className="legal-assistant-bottom-right-card-heading">
                    <h4>Why are you filing this motion?</h4>
                    <img src={closeIcon} alt="" className="legal-assistant-close-icon" onClick={() => setShowMotionPrompt(true)} />
                  </div>
                  <div className="legal-assistant-bottom-right-card-body">
                    <textarea name="motionPrompt" disabled={true} value={motionPrompt} onChange={(e) => setMotionPrompt(e.target.value)} placeholder="Describe motion's purpose" />
                    <Button className="button--primary button--full-width button-disabled">Continue</Button>
                  </div>
                </div>
              )}
            </div>
            <div className="legal-assistant-right-text">
              <p>
                Experience interactive legal assistance like never before with Atticus. Atticus analyzes your clients' case dockets and gives you an instant summary of the case posture. He advises you as to the likely outcome based on comprehensive analysis of similarly situated defendants. Then, he
                recommends several motions for you to file. Atticus can draft entire motions and briefs in mere seconds based on the case data.
              </p>
              <p>
                Our AI-powered chat interface is designed to provide comprehensive answers to your legal questions, whether you're seeking guidance on complex legal matters or simply expanding your legal knowledge. Atticus can answer any questions related to law and the cases you are working on.
              </p>
              <p>
                Upload case documents, receive recommendations, and get personalized insights to help you navigate the complexities of the legal world with confidence. With Atticus by your side, you can access expert legal guidance anytime, anywhere, empowering you to make informed decisions and
                achieve favorable outcomes in your legal endeavors.
              </p>
            </div>
          </div>
        </div>
      </section>
      <DataApplicationSection />
    </>
  );
};

export default LegalAssistantSection;
