import React from 'react';
import './NotificationBadge.css';

const NotificationBadge = ({ count, className, style }) => {
  return (
    <div className={`notification-badge ${className}`} style={style}>
      {count}
    </div>
  )
}

export default NotificationBadge;
