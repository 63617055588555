import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.css';
import { ReactComponent as CalendarIcon } from '@icons/calendar-icon.svg';

const CustomDatePicker = ({ placeholder, name, value, onChange, disabled = false, dateFormat = "dd MMM, yyyy" }) => {
    return (
        <div className="date-picker-wrapper">
            <DatePicker
                placeholderText={placeholder}
                name={name}
                selected={value}
                onChange={(date) => onChange(date)}
                dateFormat={dateFormat}
                className="date-picker-input"
                disabled={disabled}
            />
            <span className="calendar-icon">
                <CalendarIcon />
            </span>
        </div>
    );
};

export default CustomDatePicker;
