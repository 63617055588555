import React, { useState } from 'react';
import './RoleDetailsCard.css';
import TextField from '@Shared/TextField/TextField';
import Button from '@Shared/Button/Button';

const RoleDetailsCard = ({ title, onContinue, className }) => {
  const [name, setName] = useState('');

  const handleContinue = (event) => {
    event.preventDefault();
    if (name) {
      onContinue(name);
    }
  };

  return (
    <div className="role-details-card">
      <h3 className="role-details-title">{title}</h3>
      <div className="role-details-content">
        <form onSubmit={handleContinue}>
          <TextField
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={`Name of the ${title}:`}
            name="name"
            type="text"
            className={className}
          />
          <Button className="button--primary cursor-pointer" type="submit">
            Continue
          </Button>
        </form>
      </div>
    </div>
  );
};

export default RoleDetailsCard;
