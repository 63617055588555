import React, { useContext } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import SharedButton from '@Shared/Button/Button';
import CaseRecommendationPannel from '../CaseRecommendationPannel';
import GenerateMotionContext from '../../GenerateMotionContext';

const MotionModalPage2 = ({ handleClose, pageIndex, advancePage }) => {
  const { setCurrentPageIndex, startProcessing } = useContext(
    GenerateMotionContext
  );

  const caseRecommendations = [
    {
      name: 'United States v. Smith, 202 F.3d 123 (2000)',
      description:
        'This case discusses the standards for admissibility of evidence in federal criminal trials.',
      id: 1,
    },
    {
      name: 'United States v. Johnson, 456 F.3d 789 (2006)',
      description:
        'In this case, the court addressed the application of sentencing guidelines in drug-related offenses.',
      id: 2,
    },
    {
      name: 'United States v. Martinez, 789 F.3d 456 (2015)',
      description:
        'This ruling explored the constitutional implications of search and seizure under the Fourth Amendment.',
      id: 3,
    },
  ];

  return (
    <GenerateMotionModalPage
      title="Case Recommendations"
      handleClose={handleClose}
      pageIndex={pageIndex}
    >
      {caseRecommendations.map((recommendation) => (
        <CaseRecommendationPannel
          name={recommendation.name}
          description={recommendation.description}
          caseRecommendation={recommendation}
        />
      ))}
      <SharedButton
        className="button--primary motion-advance-button"
        onClick={() => {
          setCurrentPageIndex(2);
          startProcessing();
          setTimeout(() => {
            setCurrentPageIndex(3);
          }, 4000);
        }}
      >
        Continue
      </SharedButton>
    </GenerateMotionModalPage>
  );
};

export default MotionModalPage2;
