import React, { useState } from 'react';
import './LegalTeamMemberCard.css';
import Button from '@Shared/Button/Button';
import editIcon from '@icons/edit-green.svg';
import deleteIcon from '@icons/delete-green-icon.svg';
import { Link } from 'react-router-dom';
import ConfirmationModal from '@Shared/ConfirmationModal/ConfirmationModal';

const LegalTeamMemberCard = ({ image, name, role, title, id }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleDeleteClick = () => {
    setShowConfirmationModal(true);
  };

  const handleCloseModal = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmDelete = () => {
    setShowConfirmationModal(false);
  };

  return (
    <>
      <div className="legal-team-member-card" key={id}>
        <img src={image} className="legal-team-member-image" alt="no image" />
        <div>
          <p className="legal-team-member-name">{name}</p>
          <p className="legal-team-member-role">
            {role} <span /> {title}
          </p>
        </div>
        <div className="legal-team-member-action-container">
          <Button className="legal-team-member-view-profile">
            <Link to={`/dashboard/team-member/${id}`}>View Profile</Link>
          </Button>
          <Link to={`/dashboard/team-member/${id}/edit`}>
            {' '}
            <img src={editIcon} alt="no image" />
          </Link>
          <img
            src={deleteIcon}
            alt="no image"
            className="delete-legal-team-member"
            onClick={handleDeleteClick}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={showConfirmationModal}
        onClose={handleCloseModal}
        heading="Confirm Deletion"
        cancelText="Cancel"
        successText="Delete"
        onSuccess={handleConfirmDelete}
      >
        <p>
          Are you sure you want to delete this team member? This action cannot
          be undone.
        </p>
      </ConfirmationModal>
    </>
  );
};

export default LegalTeamMemberCard;
