import React, { useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import GenerateMotionContext from '../../GenerateMotionContext';

const GenerateMotionModalPage = ({
  title,
  children,
  pageIndex,
  className = '',
}) => {
  const { currentPageIndex } = useContext(GenerateMotionContext);
  if (currentPageIndex !== pageIndex) {
    return null;
  }

  return (
    <div className={className}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </div>
  );
};

export default GenerateMotionModalPage;
