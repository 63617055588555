import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from '@assets/images/atticus/icons/arrow-right-green.svg';
import './PacerResultsTable.css';

const PacerResultsTable = ({ cases, onArrowClick, isLoading }) => {
  return (
    <div className="pacer-results-table">
      <ul>
        <li className="pacer-results-table-heading">
          <p>Party Name</p>
          <p>Court</p>
          <p>Case</p>
          <p># Docket Entries</p>
          <p>Date Filled</p>
          <p>Date Closed</p>
          <p>Action</p>
        </li>
        {cases?.length > 0 ? 
          cases.map((caseItem, index) => (
            <li key={index} className="pacer-results-table-row">
              <p>{caseItem.caseName}</p>
              <p>{caseItem.court}</p>
              <p className="text-green-dark">
                <Link to={caseItem.absolute_url}>{caseItem.docketNumber}</Link>
              </p>
              <p>{caseItem.docket_id}</p>
              <p>{caseItem.dateFiled ? new Date(caseItem.dateFiled).toLocaleDateString() : ''}</p>
              <p>{caseItem.dateTerminated ? new Date(caseItem.dateTerminated).toLocaleDateString() : ''}</p>

              <div className="pacer-results-actions-container">
                <Arrow onClick={() => onArrowClick(caseItem)} />
              </div>
            </li>
          )) : (
            <>
              {!isLoading &&   <li className='text-center mt-3'>
              <p>No relevant cases records were found.</p>
            </li>}
          
            </>
          )
        }
      </ul>
    </div>
  );
};

export default PacerResultsTable;