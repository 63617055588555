import React, { useContext } from 'react';
import './Research.css';
import Header from '../Shared/Header/Header';
import Message from './Message/Message';
import ChatContext from '../ChatContext';
import ChatInputBox from '../Shared/ChatInputBox/ChatInputBox';
import ProcessingMessageBubble from './ProcessingMessageBubble/ProcessingMessageBubble';

const Research = () => {
  const { waitingForResponse, setWaitingForResponse, messages, addMessage } =
    useContext(ChatContext);

  return (
    <section className="chat-research-section">
      <Header />
      <div className="chat-research-container">
        <div className="chat-research-content">
          <p className="chat-research-date">Today, 09:00 AM</p>
          {messages.map((message) => (
            <Message
              key={message.id}
              content={message.content}
              userIsAuthor={message.userIsAuthor}
            />
          ))}
        </div>
        {waitingForResponse && <ProcessingMessageBubble />}
        <ChatInputBox />
      </div>
      {/* Add Footer Here if needed */}
    </section>
  );
};

export default Research;
