import React, { useEffect, useState } from 'react';
import TextField from '@Shared/TextField/TextField';
import Button from '@Shared/Button/Button';
import DatePicker from '@Shared/DatePicker/DatePicker';
import './NewSearchCase.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const NewSearchCase = ({ onClose, caseDetails }) => {
  const [formData, setFormData] = useState({
    partyName: '',
    court: '',
    caseNumber: '',
    docketEntries: '',
    dateFiled: null,
    dateClosed: null,
    docketId: '',
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (caseDetails) {
      setFormData({
        partyName: caseDetails.case_name || '',
        court: caseDetails.court_id || '',
        caseNumber: caseDetails.docket_number || '',
        docketEntries: caseDetails.court_id || '',
        dateFiled: caseDetails.date_filed
          ? new Date(caseDetails.date_filed)
          : null,
        dateClosed: caseDetails.date_terminated
          ? new Date(caseDetails.date_terminated)
          : null,
        docketId: caseDetails.docketId || '',
      });
    }
  }, [caseDetails]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: date });
  };

  const handleCancel = (e) => {
    setFormData({
      partyName: '',
      court: '',
      caseNumber: '',
      docketEntries: '',
      dateFiled: null,
      dateClosed: null,
    });
    onClose();
  };

  const handleAddCase = () => {
    if (formData.docketId) {
      navigate(`/dashboard/cases/${formData.docketId}/docketupload`);
    } else {
      toast.error('Docket ID is missing');
    }
  };
  return (
    <div className="new-search-case-modal">
      <div className="new-search-case-group">
        <label htmlFor="partyName">Party Name</label>
        <TextField
          placeholder="Party Name"
          name="partyName"
          value={formData.partyName}
          onChange={handleChange}
          className="new-search-case-input"
          disabled
        />
      </div>
      <div className="new-search-case-group">
        <label htmlFor="court">Court</label>
        <TextField
          placeholder="Court"
          name="court"
          value={formData.court}
          onChange={handleChange}
          className="new-search-case-input"
          disabled
        />
      </div>
      <div className="new-search-case-group">
        <label htmlFor="caseNumber">Case Number Full</label>
        <TextField
          placeholder="Case Number Full"
          name="caseNumber"
          value={formData.caseNumber}
          onChange={handleChange}
          className="new-search-case-input"
          disabled
        />
      </div>
      <div className="new-search-case-group">
        <label htmlFor="docketEntries">Number of Docket Entries</label>
        <TextField
          placeholder="Number of Docket Entries"
          name="docketEntries"
          value={formData.docketEntries}
          onChange={handleChange}
          className="new-search-case-input"
          disabled
        />
      </div>
      <div className="new-search-case-date-picker-group">
        <div className="new-search-case-group">
          <label htmlFor="dateFiled">Date Filed</label>
          <DatePicker
            placeholder="Date Filed"
            name="dateFiled"
            value={formData.dateFiled}
            selected={formData.dateFiled}
            onChange={(date) => handleDateChange('dateFiled', date)}
            disabled={true}
          />
        </div>
        <div className="new-search-case-group">
          <label htmlFor="dateClosed">Date Closed</label>
          <DatePicker
            placeholder="Date Closed"
            name="dateClosed"
            value={formData.dateClosed}
            selected={formData.dateClosed}
            onChange={(date) => handleDateChange('dateClosed', date)}
            disabled={true}
          />
        </div>
      </div>
      <Button
        className="button--primary button--full-width"
        onClick={handleAddCase}
      >
        Add Case
      </Button>
      <Button className="" onClick={handleCancel}>
        Cancel
      </Button>
    </div>
  );
};

export default NewSearchCase;
