import React, { useContext, useState } from 'react';
import GenerateMotionContext from './GenerateMotionContext';
import { ReactComponent as StatusDone } from '@icons/status-done.svg';
import { ReactComponent as StatusProcessing } from '@icons/status-processing.svg';
import { ReactComponent as StatusQueued } from '@icons/status-queued.svg';
import { useNavigate } from 'react-router-dom';

const GenerateMotionProvider = ({ children }) => {
  const navigate = useNavigate();
  const [selectedMotion, setSelectedMotion] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ query: '' });
  const [selectedCaseRecommendations, setSelectedCaseRecommendations] =
    useState([]);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const generatingMotionSteps = [
    {
      status: StatusQueued,
      text: 'User information loaded',
    },
    {
      status: StatusQueued,
      text: 'Loading case background',
    },
    {
      status: StatusQueued,
      text: 'Loading motion template',
    },
    {
      status: StatusQueued,
      text: 'Genearting a top caption',
    },
    {
      status: StatusQueued,
      text: 'Researching precedents',
    },
    {
      status: StatusQueued,
      text: 'Formulating legal arguments',
    },
    {
      status: StatusQueued,
      text: 'Inserting references',
    },
  ];
  const closeModal = () => {
    setShowModal(false);
    setCurrentPageIndex(0);
  };
  const [processingSteps, setProcessingSteps] = useState(generatingMotionSteps);
  const startProcessing = (callback) => {
    console.log('start processing');
    for (let i = 0; i < processingSteps.length; i++) {
      setTimeout(() => {
        const newSteps = [...processingSteps];
        newSteps[i].status = StatusProcessing;
        setProcessingSteps(newSteps);
        setTimeout(() => {
          const newSteps2 = [...processingSteps];
          newSteps2[i].status = StatusDone;
          setProcessingSteps(newSteps2);
        }, 500 * (i + 1));
      }, 500 * (processingSteps.lengths + 1));
    }
    setTimeout(() => {
      console.log('should set callback function for', callback);
      if (typeof callback === 'function') {
        console.log('calling callback function');
        callback();
      }
    }, (processingSteps.length + 1) * 700);
  };

  const downloadMotion = () => {
    // TODO: Rewrite this function
    const url = `${process.env.PUBLIC_URL}/motion.docx`;
    navigate(url);
  };

  return (
    <GenerateMotionContext.Provider
      value={{
        selectedMotion,
        setSelectedMotion,
        showModal,
        setShowModal,
        formData,
        setFormData,
        currentPageIndex,
        setCurrentPageIndex,
        processingSteps,
        setProcessingSteps,
        startProcessing,
        closeModal,
        selectedCaseRecommendations,
        setSelectedCaseRecommendations,
        downloadMotion,
      }}
    >
      {children}
    </GenerateMotionContext.Provider>
  );
};

export default GenerateMotionProvider;
