import React from 'react';
import './CaseLegalTeam.css';
import Button from '@Shared/Button/Button';
import { legalTeamMembersData } from '../../../LegalTeams/LegalTeamsIndex/legalTeamData';
import { Link } from 'react-router-dom';
import GroupAvatar from '@Shared/GroupAvatar/GroupAvatar';

const CaseLegalTeam = () => {
  const totalMembers = legalTeamMembersData?.length || 0;

  return (
    <div className="team-container white-background rounded-border">
      <div className="case-team-left">
        <GroupAvatar legalTeam={legalTeamMembersData} />
        <div className="case-team-details">
          <h2>Case Legal Team</h2>
          <p>{totalMembers} members</p>
        </div>
      </div>
      <div className="case-team-right">
        <Link to="#" className="manage-team-link">
          Manage Team
        </Link>
        <Button className="button--primary case-add-members-btn">
          + Add Members
        </Button>
      </div>
    </div>
  );
};

export default CaseLegalTeam;
