export const compareTableData = [
  {
    aspect: "Text Origin",
    fourthAmendment: "Derived from the Fifth Amendment protection against self-incrimination.",
    fifthAmendment: "Stated explicitly in the Sixth Amendment.",
  },
  {
    aspect: "Trigger",
    fourthAmendment: "Applies once an individual is in police custody and before interrogation.",
    fifthAmendment: "Applies once formal charges are filed or during critical stages of prosecution.",
  },
  {
    aspect: "Purpose",
    fourthAmendment: "To prevent self-incrimination during police interrogations.",
    fifthAmendment: "To ensure a fair trial and legal representation.",
  },
  {
    aspect: "Impact",
    fourthAmendment: "Requires police to inform suspects of their rights before questioning.",
    fifthAmendment: "Requires the state to provide an attorney if the accused cannot afford one.",
  },
];
