import React from 'react';
import './ChatHistory.css';
import { formatDate } from '../../../../../../lib/utils/dateFormatHelpers';

const ChatHistory = () => {
  const chats = [
    { date: '2024-10-21', title: 'New Chat' },
    { date: '2024-10-21', title: 'Demo Criminal Case' },
    { date: '2024-10-20', title: 'Randy Rudolph Law Case' },
  ];

  const today = new Date().toISOString().split('T')[0];
  const yesterday = new Date(Date.now() - 864e5).toISOString().split('T')[0];

  const renderChats = (date, label) => {
    const filteredChats = chats.filter((chat) => chat.date === date);
    if (filteredChats.length === 0) return null;

    return (
      <div key={date}>
        <h4 className="chat-history-date-label">{label}</h4>
        {filteredChats.map((chat, index) => (
          <div className="chat-history-item" key={index}>
            {chat.title}
          </div>
        ))}
      </div>
    );
  };

  const renderEarlierChats = () => {
    const earlierChats = chats.filter((chat) => chat.date !== today && chat.date !== yesterday);
    const groupedChats = earlierChats.reduce((acc, chat) => {
      acc[chat.date] = acc[chat.date] || [];
      acc[chat.date].push(chat);
      return acc;
    }, {});

    return Object.keys(groupedChats).map((date) => (
      <div key={date}>
        <h4 className="chat-history-date-label">{formatDate(date)}</h4>
        {groupedChats[date].map((chat, index) => (
          <div className="chat-history-item" key={index}>
            {chat.title}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div className="chat-history-container">
      <div className="chat-history-header">
        <h2>Chat History</h2>
        <button className="chat-history-add-chat-button">+</button>
      </div>

      <div className="chat-history-list">
        {renderChats(today, 'Today')}
        {renderChats(yesterday, 'Yesterday')}
        {renderEarlierChats()}
      </div>
    </div>
  );
};

export default ChatHistory;
