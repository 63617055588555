import React from "react";
import "./FeatureCard.css";
const FeatureCard = ({ title, content, icon }) => {
  return (
    <div className="feature-card">
      <img src={icon} alt="no icon" className="icon" />
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  );
};

export default FeatureCard;
