import React from 'react';
import { ReactComponent as FileIcon } from '@icons/selected-document-file.svg';
import { ReactComponent as DeleteIcon } from '@icons/trashcan.svg';
import './SelectedFile.css';

const SelectedFile = ({ fileName, onDelete }) => {
  return (
    <div className="selected-file-container">
      <FileIcon className="selected-file-icon" />
      <span className="selected-file-name">{fileName}</span>
      <DeleteIcon className="selected-delete-icon" onClick={onDelete} />
    </div>
  );
};

export default SelectedFile;
