// Create common types for a specific resource
const addCommonActionTypes = (resource) => {
  return {
    GET_ONE: `GET_${resource}`,
    GET_ALL: `GET_${resource}S`,
    EDIT_ONE: `EDIT_${resource}`,
    CREATE_ONE: `CREATE_${resource}`,
    UPDATE_ONE: `UPDATE_${resource}`,
    DELETE_ONE: `DELETE_${resource}`,
  };
}


// Auth
export const authTypes = {
  LOG_IN: 'LOG_IN',
  LOG_OUT: 'LOG_OUT',
  SIGN_UP: 'SIGN_UP',
  REGISTER: 'REGISTER_EMAIL',
}

// Users
export const userTypes = {
  ...addCommonActionTypes('USER'),
  SET_USER: 'SET_USER',
}

// Cases
export const caseTypes = {
  GET_CASES: 'GET_CASES',
  SET_CASES: 'SET_CASES',
}

// Legal Team
export const legalTeamTypes = {
  ...addCommonActionTypes('LEGAL_TEAM'),
  SET_LEGAL_TEAM: 'SET_LEGAL_TEAM',
}
