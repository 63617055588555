import React from 'react';
import { RotatingLines } from 'react-loader-spinner';
import './LoaderSpinner.css';

const LoaderSpinner = ({ color = 'silver', width = 40, animationDuration = 0.75 }) => {
  return (
    <div className="loader-spinner-rotating-lines">
      <RotatingLines
        strokeColor={color}
        strokeWidth="4"
        animationDuration={animationDuration}
        width={width}
        visible={true}
      />
    </div>
  );
};

export default LoaderSpinner;
