import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './GenerateMotionModal.css';

import MotionModalPage1 from './GenerateMotionModalPages/MotionModalPage1';
import MotionModalPage2 from './GenerateMotionModalPages/MotionModalPage2';
import MotionModalPage3 from './GenerateMotionModalPages/MotionModalPage3';
import MotionModalPage4 from './GenerateMotionModalPages/MotionModalPage4';
import GenerateMotionContext from '../GenerateMotionContext';
const GenerateMotionModal = () => {
  const { showModal, setShowModal, closeModal } = useContext(
    GenerateMotionContext
  );

  const handleShow = () => setShowModal(true);
  const handleClose = () => closeModal();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const advancePage = (newPageIndex = false) => {
    if (newPageIndex) {
      console.log('advancing page to', newPageIndex);

      setCurrentPageIndex(newPageIndex);
      return;
    }
    console.log('advancing page to', currentPageIndex + 1);
    setCurrentPageIndex(currentPageIndex + 1);
  };

  return (
    <div>
      {showModal && (
        <div className="generate-motion-modal-overlay">
          <Modal
            show={handleShow}
            onHide={handleClose}
            className="generate-motion-modal"
          >
            <MotionModalPage1
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={0}
              advancePage={advancePage}
            />
            <MotionModalPage2
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={1}
              advancePage={advancePage}
              setCurrentPageIndex={setCurrentPageIndex}
            />
            <MotionModalPage3
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={2}
              advancePage={advancePage}
            />
            <MotionModalPage4
              handleClose={handleClose}
              currentPageIndex={currentPageIndex}
              pageIndex={3}
              advancePage={advancePage}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default GenerateMotionModal;
