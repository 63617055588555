import firstEngineer from "./../../../../../assets/images/atticus/team/omer-obaid.png";
import secondEngineer from "./../../../../../assets/images/atticus/team/engineer-five.png";
import thirdEngineer from "./../../../../../assets/images/atticus/team/engineer-seven.png";
import fourthEngineer from "./../../../../../assets/images/atticus/team/engineer-eight.png";
import fifthEngineer from "./../../../../../assets/images/atticus/team/engineer-nine.png";
import sixthEngineer from "./../../../../../assets/images/atticus/team/engineer-ten.png";
import seventhEngineer from "./../../../../../assets/images/atticus/team/enginner-eleven.png";
import eightEngineer from "./../../../../../assets/images/atticus/team/mel.png";

export const currentTeamImages = [fourthEngineer, thirdEngineer, fifthEngineer, sixthEngineer, secondEngineer, seventhEngineer, firstEngineer, eightEngineer];
