import React from 'react';
import './ButtonPrimary.css';

const ButtonPrimary = ({ children, style, ...props }) => {
  return (
    <button className="button-primary" {...props} style={style}>
      {children}
    </button>
  );
}

export default ButtonPrimary;
