import React, { useEffect } from 'react';
import './Layout.css';
import { Outlet, Link } from 'react-router-dom';
import ChatProvider from '../ChatProvider';
import Logo from '@icons/chat-item-icon.svg';
import CamerIcon from '@icons/camera-icon.svg';
import UsersIcon from '@icons/users-icon.svg';
import UserIcon from '@icons/user-icon.svg';
import LogoutIcon from '@icons/logout-icon.svg';
import ChatIcon from '@icons/chat-button-icon.svg';
import Button from '@Shared/Button/Button';
import ChatHistory from '../Shared/ChatHistory/ChatHistory';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { error } from '@lib/utils/toast';

const Layout = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      error('Please log in.');
    }
  }, [isAuthenticated, navigate]);

  const menuItems = [
    { icon: CamerIcon, route: '/chat' },
    { icon: UsersIcon, route: '/dashboard/team' },
    { icon: UserIcon, route: '/dashboard/account/' },
    { icon: LogoutIcon, route: '/dashboard' },
  ];

  return (
    <ChatProvider>
      <div className="chatroom-layout-container">
        <aside className="chatroom-left-sidebar">
          <div className="atticus-chat-logo-container">
            <img src={Logo} alt="Chat Logo" />
          </div>
          <div className="chat-room-sidebar-content">
            <div className="atticus-chat-side-icon-container">
              {menuItems.map((item, index) => (
                <Link
                  to={item.route}
                  key={index}
                  aria-label={`Navigate to ${item.route}`}
                >
                  <img
                    src={item.icon}
                    alt={`Menu icon ${index + 1}`}
                    className="atticus-chat-icon-container"
                  />
                </Link>
              ))}
            </div>
            <Link to={'/chat'}>
              <Button className="button--primary chat-button--primary">
                <img src={ChatIcon} alt="Chat Button" />
              </Button>
            </Link>
          </div>
        </aside>
        <main className="chatroom-content-container">
          <Outlet />
        </main>
        <aside className="chatroom-right-sidebar">
          <ChatHistory />
        </aside>
      </div>
    </ChatProvider>
  );
};

export default Layout;
