import React, { useState, useEffect } from 'react';
import PageTitle from '../../Shared/PageTitle/PageTitle';
import PacerResultsTable from './PacerResultsTable/PacerResultsTable';
import { useLocation } from 'react-router-dom';
import {
  advancedSearch,
  getCaseMetadataFromDocketId,
} from '@lib/apis/pacer-api';
import Loader from '@Shared/Loader/Loader';
import Drawer from '@Shared/Drawer/Drawer';
import NewSearchCase from './NewSearchCase/NewSearchCase';
import { toast } from 'react-toastify';
import { formatToYYYYMMDD } from '@lib/utils/dateFormatHelpers';
import SearchSection from '../../Atticus/SearchSection/SearchSection';
import { useOutletContext } from 'react-router-dom';

const CasesSearch = () => {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const { selectedCase, setSelectedCase } = useOutletContext();
  const [error, setError] = useState('');
  const location = useLocation();

  const advancedSearchData = location?.state?.advancedSearch || {};
  const { firstName, lastName, caseNumber, court, dateFiled, dateClosed } =
    advancedSearchData.data || {};
  const initialQuery = advancedSearchData.query || '';

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const isAdvancedSearch =
          firstName ||
          lastName ||
          caseNumber ||
          court ||
          dateFiled ||
          dateClosed;
        const payloadAdvancedSearch = {
          firstName,
          lastName,
          searchType: 'advanced',
          caseNumberFull: caseNumber,
          courtId: court,
          dateFiledFrom: dateFiled ? formatToYYYYMMDD(dateFiled) : '',
          dateFiledTo: dateClosed ? formatToYYYYMMDD(dateClosed) : '',
        };

        const data = await advancedSearch(
          isAdvancedSearch
            ? payloadAdvancedSearch
            : { searchType: 'basic', name: initialQuery }
        );

        if (data?.data?.error) {
          setError(data?.data?.error ?? 'Something Went Wrong');
        } else {
          setCases(data?.data?.results || []);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    initialQuery,
    firstName,
    lastName,
    caseNumber,
    court,
    dateFiled,
    dateClosed,
  ]);

  const handleArrowClick = async (caseItem) => {
    setLoading(true);
    const response = await getCaseMetadataFromDocketId(caseItem.docket_id);
    setLoading(false);
    if (response && response.success === true) {
      setSelectedCase({ ...response.data, docketId: caseItem.docket_id });
      toggleDrawer();
    } else {
      toast.error('Something went wrong');
    }
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  return (
    <main className="dashboard-page case-search-page">
      {initialQuery && (
        <PageTitle title={`Search Results for: ${initialQuery}`} subtitle=" " />
      )}
      <PageTitle subtitle={`Cases matching search: ${cases.length || 0}`} />
      <SearchSection
        initialValues={{
          firstName: firstName || '',
          lastName: lastName || '',
          caseNumber: caseNumber || '',
          court: court || '',
          dateFiled: dateFiled || '',
          dateClosed: dateClosed || '',
        }}
      />
      <PacerResultsTable
        cases={cases}
        isLoading={loading}
        onArrowClick={handleArrowClick}
      />
      <Drawer
        title={'New Criminal Case'}
        isOpen={openDrawer}
        onClose={toggleDrawer}
      >
        {selectedCase && (
          <NewSearchCase caseDetails={selectedCase} onClose={toggleDrawer} />
        )}
      </Drawer>
      {loading && <Loader />}
    </main>
  );
};

export default CasesSearch;
