import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./global.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import store from './store';
import store from "./state/store"; // Corrected import statement
//import awsExports from "./aws-exports"; // Corrected import statement
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//Amplify.configure(awsExports);

// Amplify.configure({
//   API: {
//     GraphQL: {
//       endpoint: 'https://37xvloagungk5pgc5czt5d5lyi.appsync-api.us-east-1.amazonaws.com/graphql',
//       region: 'us-east-1',
//       // Set the default auth mode to "apiKey" and provide the API key value
//       defaultAuthMode: 'apiKey',
//       apiKey: 'da2-vmj3gauzyncxrck5fhpihuwlyq'
//     }
//   }
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
