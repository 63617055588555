import React from 'react';
import './MessageCard.css';
import messageCardIcon from '@icons/chat-item-icon.svg';
import downloadIcon from '@icons/file-download-profile.svg';
import retryIcon from '@icons/retry-icon.svg';

const MessageCard = ({ text, handleRetry, generatedData, maxWidth = '100%', isLogo = true }) => {
  const handleDownload = () => {
    console.log('Download Clicked');
  };
  return (
    <div className="chat-item-text-container" style={{ maxWidth: maxWidth }}>
      <div className="chat-item-content-container">
        {isLogo && <img src={messageCardIcon} alt="no icon" />}
        <div>
          {text && <p>{text}</p>}

          {generatedData && (
            <div className={`generated-data-container ${text ? '' : 'mt-0'}`}>
              {generatedData?.map((section, key) => {
                return (
                  <>
                    <strong>{section?.title} </strong>
                    <p> {section?.text} </p>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {generatedData ? (
        <div className="message-card-footer">
          <div className="message-card-download-container" onClick={handleDownload}>
            <img src={downloadIcon} alt="Download" />
            <span>Download Profile</span>
          </div>
          {handleRetry && <img src={retryIcon} alt="Retry" className="message-card-retry-icon" onClick={handleRetry} />}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MessageCard;
