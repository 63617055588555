import React from 'react';

import './BadgeInfo.css';

const BadgeInfo = ({ children, hasAdditionalItems, className }) => {

  return (
    <div className={`badge-info ${className} ${hasAdditionalItems ? 'additional-items' : ''}`}>
      {children}
    </div>
  );
}

export default BadgeInfo;
