import { useParams } from 'react-router-dom';
import './UploadDocuments.css';
import Button from '@Shared/Button/Button';
import uploadFileIcon from '@assets/images/atticus/icons/file-upload-fill.svg';
import DataTable from '@Shared/DataTable/DataTable';
import actionIcon from '@assets/images/atticus/icons/more-line.svg';
import ActionMenu from '@Shared/ActionMenu/ActionMenu';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GrDocumentDownload } from 'react-icons/gr';
import { useState } from 'react';

const UploadDocuments = () => {
  const { id } = useParams();

  // TODO: Get documents from API
  const [documents, setDocuments] = useState([
    {
      id: 1,
      dateTime: 'Apr 22, 2024 @ 11:03 PM',
      name: 'MOTION to Seal Case by USA as to DONALD J. TRUMP. (Attachments: #1 Text of Proposed Order)',
    },
    {
      id: 2,
      dateTime: 'Apr 22, 2024 @ 11:03 PM',
      name: 'MOTION to Seal Case by USA as to DONALD J. TRUMP. (Attachments: #1 Text of Proposed Order)',
    },
    {
      id: 3,
      dateTime: 'Apr 22, 2024 @ 11:03 PM',
      name: 'MOTION to Seal Case by USA as to DONALD J. TRUMP. (Attachments: #1 Text of Proposed Order)',
    },
    {
      id: 4,
      dateTime: 'Apr 22, 2024 @ 11:03 PM',
      name: 'MOTION to Seal Case by USA as to DONALD J. TRUMP. (Attachments: #1 Text of Proposed Order)',
    },
  ]);

  const menuItems = [
    {
      label: 'Download document',
      labelColor: '#344054',
      icon: <GrDocumentDownload />,
      onClick: () => console.log('Download clicked'),
    },
    {
      label: 'Delete document',
      labelColor: '#F2295B',
      icon: <FaRegTrashAlt style={{ color: 'red' }} />,
      onClick: () => console.log('Delete clicked'),
    },
  ];

  const renderRow = (row) => (
    <>
      <td>{row.dateTime}</td>
      <td>{row.name}</td>
      <td>
        <ActionMenu icon={actionIcon} items={menuItems} />
      </td>
    </>
  );

  return (
    <main className="upload-document-page rounded-border white-background">
      <div className="upload-document-card-header">
        <div className="upload-documents-info">
          <h4>Uploaded Documents</h4>
          <p>{documents?.length ?? 0} documents</p>
        </div>
        <div className="upload-button-container">
          <Button className="button--primary">
            <div className="button-content">
              <img
                src={uploadFileIcon}
                alt="Upload Icon"
                className="upload-icon"
              />
              <span>Upload New Document</span>
            </div>
          </Button>
        </div>
      </div>
      <DataTable
        headings={['Date & Time', 'Document Name', 'Action']}
        data={documents}
        renderRow={renderRow}
        isPagination={false}
      />
    </main>
  );
};

export default UploadDocuments;
