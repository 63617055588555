import React from 'react';
import './AnalyzeMessageCard.css';
import downloadIcon from '@icons/file-download-profile.svg';
import retryIcon from '@icons/retry-icon.svg';

const AnalyzeMessageCard = ({ generatedData, handleRetry }) => {
  const handleDownload = () => {
    console.log('Download Clicked');
  };

  return (
    <div className="analyze-message-card">
      <div className="analyze-message-card-content">
        <div className="analyze-message-card-text">
          <p>
            <span> Here’s an initial analysis of the document:</span>
          </p>
          {generatedData && (
            <div className="analyze-message-card-details">
              {generatedData.map((section, index) => (
                <div key={index}>
                  <strong className="me-1">{section.title}</strong>
                  <span>{section.text}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {generatedData && (
        <div className="message-card-footer">
          <div className="download-btn cursor-pointer" onClick={handleDownload}>
            <img src={downloadIcon} alt="Download" className="me-1" />
            <span>Download Analysis</span>
          </div>
          {handleRetry && <img src={retryIcon} alt="Retry" className="retry-btn cursor-pointer" onClick={handleRetry} />}
        </div>
      )}
    </div>
  );
};

export default AnalyzeMessageCard;
