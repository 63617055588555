import React, { useContext } from 'react';
import './ProcessingMessageBubble.css';

const ProcessingMessageBubble = () => {
  return (
    <div className="processing-message-bubble">
      <div class="processing-message-dot"></div>
      <div class="processing-message-dot"></div>
      <div class="processing-message-dot"></div>
    </div>
  );
};

export default ProcessingMessageBubble;
