import checkMarkIcon from "./../../../../../assets/images/atticus/icons/check-line-green.svg";
import arrowRightBlackIcon from "./../../../../../assets/images/atticus/icons/arrow-right-gray.svg";
import loopIcon from "./../../../../../assets/images/atticus/icons/loading-icon.svg";

export const legalInsightsDataArray = [
  {
    image: checkMarkIcon,
    text: "User information loaded",
  },
  {
    image: loopIcon,
    text: "Loading case background",
  },
  {
    image: arrowRightBlackIcon,
    text: "Loading motion template",
  },
  {
    image: arrowRightBlackIcon,
    text: "Generating a top caption",
  },
  {
    image: arrowRightBlackIcon,
    text: "Researching precedents",
  },
  {
    image: arrowRightBlackIcon,
    text: "Formulating legal arguments",
  },
  {
    image: arrowRightBlackIcon,
    text: "Inserting references",
  },
];
