import React, { useState } from 'react';
import FileUpload from '../../../../../Chat/Workflow/Shared/FileUpload/FileUpload';
import './DocketCriminalCase.css';
import { RiFileUploadLine } from 'react-icons/ri';
import closeIcon from '@assets/images/atticus/icons/close-gray-icon.svg';

const DocketCriminalCase = ({ filing, enteredDate }) => {
  const [fileName, setFileName] = useState('');

  const handleFileDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setFileName(file.name);
    }
  };
  return (
    <>
      <div className="main-criminal-docket-case-container">
        <div className="docket-case-upload-file drag-file-upload-content ">
          <FileUpload
            height="auto"
            onDrop={handleFileDrop}
            fileName={fileName}
            isDragDropText={true}
          />
        </div>

        <div className="criminal-case-file-upload-status-item success">
          <div className="criminal-case-file-upload-status-icon">
            <RiFileUploadLine className="file-upload-success-icon" />
          </div>

          <div className="criminal-case-status-details">
            <p>
              {filing} <br />
              {/* (zltp) <br /> (Entered: {enteredDate}) <br /> */}
              <span className="progress-status-text">100kb • Complete</span>
            </p>

            <div className="progress-bar-indicator">
              <div className="progress-bar-fill success-fill"></div>
            </div>
          </div>
          <img
            src={closeIcon}
            alt="Close"
            className="close-icon-criminal-case"
          />
        </div>

        <div className="criminal-case-file-upload-status-item error">
          <div className="criminal-case-file-upload-status-icon">
            <RiFileUploadLine className="file-upload-error-icon" />
          </div>
          <div className="status-detail-error-block">
            <p>
              Upload failed.
              <br /> <span>File too large • Failed </span>
            </p>
          </div>
          <img
            src={closeIcon}
            alt="Close"
            className="close-icon-criminal-case"
          />
        </div>
      </div>
    </>
  );
};

export default DocketCriminalCase;
