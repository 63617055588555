import React from 'react';
import './Message.css';
import MessageCard from '../../Shared/MessageCard/MessageCard';

const Message = ({ content, userIsAuthor, speed = 0 }) => {
  if (userIsAuthor) {
    return (
      <div className="chat-argument-message user-chat-message">
        <p>{content}</p>
      </div>
    );
  } else {
    return <MessageCard text={content} maxWidth="80%" />;
  }
};

export default Message;
