import React from 'react';
import { ReactComponent as TextBlockIcon} from '@icons/text-block.svg';
import './TextBlock.css';

const TextBlock = ({ text }) => {
    return (
        <>

            <TextBlockIcon className='text-block-icon' />
            <div className="text-block">
                {text}
            </div>
        </>

    );
};



export default TextBlock;
