import React from 'react';
import './ConfirmationModal.css';
import Button from '../Button/Button';

/**
 * ConfirmationModal Component
 * 
 * @param {boolean} isOpen - A boolean that determines whether the modal is open or closed.
 * @param {function} onClose - A callback function that is triggered when the modal is closed.
 * @param {string} [heading="Default Title"] - The heading text for the modal. Defaults to "Default Title" if not provided.
 * @param {string} [Children] - The body displayed within the modal.
 * @param {string} [cancelText="Cancel"] - The text displayed on the cancel button. Defaults to "Cancel" if not provided.
 * @param {string} [successText="Approve"] - The text displayed on the success/confirmation button. Defaults to "Approve" if not provided.
 * @param {function} onSuccess - A callback function that is triggered when the success button is clicked.
 */

const ConfirmationModal = ({
    children,
    isOpen,
    onClose,
    heading = "Default Title",
    cancelText = "Cancel",
    successText = "Approve",
    onSuccess
}) => {

    const handleSuccess = () => {
        if (onSuccess) onSuccess();
        onClose();
    };

    return (
        <div className={`confirmation--modal ${isOpen ? 'open' : ''}`} onClick={onClose}>
            <div className="confirmation--modal-content" onClick={e => e.stopPropagation()}>
                <div className="confirmation--modal-header">{heading}</div>
                <div className="confirmation--modal-body">
                    {children}
                </div>
                <div className="confirmation--modal-footer">
                    <Button className="button--transparent" onClick={onClose}>
                        {cancelText}
                    </Button>
                    <Button className="button--primary" onClick={handleSuccess}>
                        {successText}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
