import React, { forwardRef } from 'react';
import MessageCard from '../../Shared/MessageCard/MessageCard';
import { trialGeneratedData } from '../trialData';
import downloadIcon from '@icons/file-download-profile.svg';
import retryIcon from '@icons/retry-icon.svg';

const TrialListing = forwardRef(({ selectedRoleData }, ref) => {
  return (
    <>
      {selectedRoleData && (
        <>
          <div ref={ref} className="trial-workflow-container">
            <MessageCard text={trialGeneratedData[3]?.messages[0]?.message} />
          </div>
          <div className="trial-generated-content">
            {trialGeneratedData?.map((data, index) => (
              <div key={index} className="trial-section">
                {data.title && (
                  <h6 className="trial-title">
                    {`${index + 1}. `} {data.title}
                  </h6>
                )}

                <ul className="trial-points-list">
                  {data.points?.map((point, idx) => (
                    <li key={idx} className="trial-point">
                      {point.point} {point.description}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className="trail-generated-last-message">{trialGeneratedData[3]?.messages[1]?.message}</div>

            <div className="download-container-trial-page">
              <img src={downloadIcon} alt="Download" />
              <span className="download-text-trial-page">Download</span>
              <img src={retryIcon} alt="Retry" className="retry-icon-trial-page" />
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default TrialListing;
