import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as Funnel } from "../../../../../../assets/images/atticus/icons/funnel.svg";
import { ReactComponent as DropdownArrowIcon } from "../../../../../../assets/images/atticus/icons/arrow-dropdown.svg";
import { getCases } from "../../../../../../lib/apis/resources/case";

import "./CaseSearchFilter.css";

const CaseSearchFilter = () => {
  const dropdownOptions = [
    { text: "All Cases", filter: "all" },
    { text: "Open Cases", filter: "open" },
    { text: "Closed Cases", filter: "closed" },
    { text: "Active Cases", filter: "active" },
    { text: "Inactive Cases", filter: "inactive" },
  ];

  const [filter, setFilter] = useState(dropdownOptions[0]);

  const filterSearch = (filterObject) => {
    getCases(filterObject.filter, "token");
    console.log("filterObject.filter", filterObject.filter);
    setFilter(filterObject);
  };

  return (
    <Dropdown className="case-search-filter-dropdown">
      <Dropdown.Toggle variant="" id="cases-table-filter-dropdown">
        <Funnel />
        <p className="cases-dropdown-toggle-text">{filter.text}</p>
        <DropdownArrowIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {dropdownOptions.map((option, index) => (
          <Dropdown.Item key={index}>
            <button
              className="cases-header-dropdown-option"
              onClick={() => filterSearch(option)}
            >
              {option.text}
            </button>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CaseSearchFilter;
