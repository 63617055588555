export const analyzeData = [
  {
    id: 1,
    title: 'Timeline Discrepancies',
  },
  {
    id: 2,
    title: 'Witness Statements'
  },
  {
    id: 3,
    title: 'Chain of Custody Issues',
    },
   {
    id: 4,
    title: 'Procedural Violations',
  },
  {
    id: 5,
    title: 'Constitutional Violations',
  },
  {
    id: 6,
    title: 'Violations of Case Law',
    },
   {
    id: 7,
    title: 'Expert Testimony',
  
  },
  {
    id: 8,
    title: 'Document Integrity',

  },
  {
    id: 9,
    title: 'Sentencing or Statutory Errors',
    question: ""
  },
 
];

// argumentData.js
export const analyzeGeneratedData = [
  {
    title: 'Inconsistencies:',
    text: 'Witness 1 stated the suspect was wearing a red jacket, while Witness 2 described a blue jacket.'
  },
  {
    title: 'Timeline Discrepancies:',
    text: 'Conflicting timelines between Witness 3 and Witness 4 regarding the suspect’s presence at the scene.'
  }
];

