/* eslint-disable */

import axios from 'axios';
import cases from '../../mocks/cases'; // Assuming you have a mock data file for cases
import { getAuthHeaders } from '../utilities';

const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

// CREATE
export const createCase = async (params, token) => {
  // TODO: Replace the next line with the actual API call
  return Object.keys(params).length === 0 ? cases[0] : { ...params, caseId: 'new-case-id' }; // Mocked response

  // try {
  //   const response = await axios.post(`${BASE_URL}/cases`, params, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error creating case:', error);
  //   return error;
  // }
}

// READ
export const getCase = async (id, token) => {
  return cases[0];
  // return id ? cases.find(c => c.caseId === id) : cases[0]; // Mocked response

  // try {
  //   const response = await axios.get(`${BASE_URL}/cases/${id}`, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error getting case:', error);
  //   return error;
  // }
}

// UPDATE
export const updateCase = async (id, params, token) => {
  return Object.keys(params).length === 0 ? cases[0] : { ...cases[0], ...params }; // Mocked response

  // try {
  //   const response = await axios.put(`${BASE_URL}/cases/${id}`, params, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error updating case:', error);
  //   return error;
  // }
}

// DELETE
export const deleteCase = async (id, token) => {
  return cases[0]; // Mocked response

  // try {
  //   const response = await axios.delete(`${BASE_URL}/cases/${id}`, getAuthHeaders(token));
  //   const { case } = response.data;
  //   return case;
  // } catch (error) {
  //   console.error('Error deleting case:', error);
  //   return error;
  // }
}

// GET ALL FOR USER
export const getCases = async (params, token) => {
  console.log('getCases', cases);
  return cases; // Mocked response

  // try {
  //   const response = await axios.get(`${BASE_URL}/cases`, getAuthHeaders(token));
  //   const { cases } = response.data;
  //   return cases;
  // } catch (error) {
  //   console.error('Error getting cases:', error);
  //   return error;
  // }
}
