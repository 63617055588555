const legalTeams = [
  {
    legalTeamId: 101,
    appId: 1,
    legalTeamName: "Alpha Team",
    userIds: [1, 2, 3]
  },
  {
    legalTeamId: 102,
    appId: 1,
    legalTeamName: "Beta Team",
    userIds: [4, 5, 6]
  },
  {
    legalTeamId: 103,
    appId: 1,
    legalTeamName: "Gamma Team",
    userIds: [7, 8, 9]
  },
  {
    legalTeamId: 104,
    appId: 1,
    legalTeamName: "Delta Team",
    userIds: [1, 3, 5]
  },
  {
    legalTeamId: 105,
    appId: 1,
    legalTeamName: "Epsilon Team",
    userIds: [2, 4, 6]
  },
  {
    legalTeamId: 106,
    appId: 1,
    legalTeamName: "Zeta Team",
    userIds: [7, 9, 10]
  },
  {
    legalTeamId: 107,
    appId: 1,
    legalTeamName: "Eta Team",
    userIds: [3, 6, 8]
  },
  {
    legalTeamId: 108,
    appId: 1,
    legalTeamName: "Theta Team",
    userIds: [1, 4, 9]
  },
  {
    legalTeamId: 109,
    appId: 1,
    legalTeamName: "Iota Team",
    userIds: [2, 5, 7]
  },
  {
    legalTeamId: 110,
    appId: 1,
    legalTeamName: "Kappa Team",
    userIds: [3, 8, 10]
  }
];

export default legalTeams;
