import React, { useState } from "react";
import "./DataApplication.css";
import checkMarkIcon from "./../../../../../../assets/images/atticus/icons/check-line-black.svg";
import { featureData } from "./featureData";
import Accordions from "../../../../../Shared/Accordion/Accordion";

const DataApplicationSection = ({ refValue }) => {
  const [selectedKeyFeatures, setSelectedKeyFeatures] = useState(featureData[0].keyFeatures);

  const handleAccordionSelect = (index) => {
    setSelectedKeyFeatures(featureData[index].keyFeatures);
  };

  return (
    <div className="data-application-section" ref={refValue}>
      <h2>Data & Application Security</h2>
      <p className="data-application-section-subheading">Your data's security is our top priority—and embedded into all our processes, which we work continually to keep improving.</p>
      <div className="data-application-section-accordions-container">
        <Accordions data={featureData} onSelect={handleAccordionSelect} />
        <div className="key-features-container">
          {selectedKeyFeatures?.map((feature, index) => (
            <div key={index} className="key-features-sub-container">
              <img src={checkMarkIcon} alt="no check mark" />
              <span> {feature} </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataApplicationSection;
