import React, { useState, useContext } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import GenerateMotionContext from '../../GenerateMotionContext';
import Button from '@Shared/Button/Button';
import { ReactComponent as DownloadIcon } from '@icons/download-file.svg';

const MotionModalPage4 = ({ pageIndex }) => {
  const { selectedMotion, downloadMotion } = useContext(GenerateMotionContext);
  const [checked, setChecked] = useState(false);

  const handleChange = (e) => {
    setChecked(e.target.value);
  };

  const downloadMotionTemp = () => {
    const url = `${process.env.PUBLIC_URL}/motion.docx`;

    window.location.href = url;
  };
  return (
    <GenerateMotionModalPage title="Atticus is done!" pageIndex={pageIndex}>
      <p>
        Your motion has been successfully generated. Please review this draft{' '}
        {selectedMotion?.name}.
      </p>
      <p>
        Note that the following does not constitute legal advice, and it is the
        responsibility of the attorney representing the client to review all
        generated content thoroughly.
      </p>
      <p>Confirm to Get Motion</p>
      <div class="download-motion-confirm-container">
        <form>
          <input
            name="checked"
            type="checkbox"
            id="download-motion-agree-checked"
            value={checked}
            onChange={handleChange}
          ></input>
          <label htmlFor="download-motion-agree-checked">
            I confirm that I have reviewed the draft and understand that the
            onus of responsibility for reviewing all generated content is upon
            the individual attorney representing a client.
          </label>
        </form>
        <Button
          onClick={downloadMotionTemp}
          className={`button--outline download-motion-button ${
            !checked ? 'motion-download-disabled' : ''
          }`}
        >
          <DownloadIcon />
          {selectedMotion?.name}
        </Button>
      </div>
    </GenerateMotionModalPage>
  );
};

export default MotionModalPage4;
