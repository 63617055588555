import React from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/images/atticus/icons/search-eye.svg';

const PacerSearchTextInput = ({name="search", defaultValue="", placeholder="Search cases...", onChange, value}) => {
  return (
    <div className="pacer-search-text-input-container">
      <input value={value} onChange={onChange} className="pacer-search-text-input" type="text" name={name} defaultValue={defaultValue} placeholder={placeholder} />
      <SearchIcon className="pacer-search-text-input-icon" />
    </div>
  );
}

export default PacerSearchTextInput;
