import { Box } from "@chakra-ui/react";
import AllRouter from "./AllRouter/AllRouter";
import "./App.css";
import AnimatedCursor from "react-animated-cursor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";


// const stripePromise = loadStripe("pk_test_51PFtNkApo9b8NbEiwK2mff1QgDi5TUfNiDiJetZ6vWWpppPCBNijUGTSrczpG6zkmCM0RTVTqU8cMqWDsdGwwKbI00LrrOWpGv");
function App() {
  // const options = {
  //   mode: 'payment',
  //   amount: 1099,
  //   currency: 'usd',
  //   // Fully customizable with appearance API.
  //   appearance: {
  //     /*...*/
  //   },
  // };

  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
  };
  return (
    <>
      <ToastContainer />
      {/* <Elements stripe={stripePromise} options={options}> */}
      <AllRouter />
      {/* </Elements> */}
    </>
  );
}

export default App;
