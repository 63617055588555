import React, { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import GenerateMotionContext from './GenerateMotionContext';
import { motionOptions } from '@lib/utils/motionDictionary';

const MotionOptionsSelect = () => {
  const { selectedMotion, setSelectedMotion } = useContext(GenerateMotionContext);
  const options = [
    { name: 'Evidence', value: 'evidence' },
    { name: 'Indictments', value: 'indictments' },
    { name: 'Time', value: 'time' },
    { name: 'Waviers', value: 'waivers' },
    { name: 'Joinders', value: 'joinders' },
    { name: 'Pre-Trial', value: 'preTrial' },
    { name: 'Subpeona', value: 'subpeona' },
    { name: 'Venue', value: 'venue' },
    { name: 'Trial', value: 'trial' },
    { name: 'Default Judgement', value: 'defaultJudgement' },
    { name: 'Pleas', value: 'pleas' },
    { name: 'Extend Time', value: 'extendTime' },
    { name: 'Suppress Evidence', value: 'suppressEvidence' },
    { name: 'Dismiss Indictment', value: 'dismissIndictment' },
  ];

  const handleChange = (e) => {
    const newOption = motionOptions.find((option) => option.value === e.target.value);
    setSelectedMotion(newOption);
  };
  return (
    <Form.Select aria-label="Default select example" onChange={handleChange} value={selectedMotion?.value || ''}>
      <option>Select Other Motions</option>
      {motionOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </Form.Select>
  );
};

export default MotionOptionsSelect;
