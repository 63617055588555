import React, { useEffect, useRef, useContext, useState } from 'react';
import './ChatInputBox.css';
import attachmentIcon from '@icons/attachment-icon.svg';
import sendIcon from '@icons/send-icon.svg';
import ChatContext from '../../ChatContext';
import { argumentGeneratedData } from '../../Workflow/Argument/aurgumentData';

const ChatInputBox = ({ type, isDefaultSelected = false }) => {
  const { waitingForResponse, setWaitingForResponse, addRequestMessage, addResponseMessage } = useContext(ChatContext);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);

  const handleAttachmentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
    }
  };

  const handleSubmit = () => {
    const requestMessage = textareaRef.current.value;
    if (!requestMessage.trim()) return;
    setWaitingForResponse(true);
    addRequestMessage(requestMessage, type ?? '', isDefaultSelected);
    textareaRef.current.value = '';
    setText('');
    autoResizeTextarea();

    // TODO: Send to API
    setTimeout(() => {
      setWaitingForResponse(false);

      // Determine the mock response based on isDefaultSelected
      const mockResponse = isDefaultSelected
        ? argumentGeneratedData[0]
        : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';

      // Add the response message
      addResponseMessage(mockResponse, type ?? '', isDefaultSelected);
    }, 2000);
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '40px';
      const newHeight = textarea.scrollHeight;

      if (newHeight > 300) {
        textarea.style.height = '300px';
        textarea.style.overflowY = 'scroll';
      } else if (textarea.value.length === 0) {
        textarea.style.height = '40px';
      } else {
        textarea.style.height = `${newHeight}px`;
        textarea.style.overflowY = 'hidden';
      }
    }
  };

  const handleTextareaChange = (event) => {
    const value = event.target.value;
    setText(value);
    autoResizeTextarea();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && text.trim()) {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    autoResizeTextarea();
  }, []);

  return (
    <div className="chat-input-box">
      <div className="chat-input-container">
        <div className="chat-attachment-icon-container" onClick={handleAttachmentClick}>
          <img src={attachmentIcon} alt="Attachment" className="attachment-icon" />
        </div>
        <div className="chat-message-text">
          <textarea
            ref={textareaRef}
            className="chat-message-input"
            placeholder="Message Atticus v0.1"
            onInput={handleTextareaChange}
            onKeyDown={handleKeyDown}
            rows={1}
          />
        </div>
      </div>
      <div className="send-message-button" onClick={handleSubmit}>
        <img src={sendIcon} alt="Send" className="send-message-icon cursor-pointer" />
      </div>
      <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
    </div>
  );
};

export default ChatInputBox;
