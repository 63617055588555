import React from 'react';
import './ComparisonTable.css';
import downloadIcon from '@icons/file-download-profile.svg';
import retryIcon from '@icons/retry-icon.svg';

const ComparisonTable = ({ data, heading, handleRetry, handleDownload }) => {
  return (
    <div className="comparison-table-container">
      <p>{heading}</p>
      <table className="comparison-table">
        <thead>
          <tr>
            <th>Aspect</th>
            <th>Fourth Amendment</th>
            <th>Fifth Amendment</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td className="first-aspect-column">{row.aspect}</td>
              <td>{row.fourthAmendment}</td>
              <td>{row.fifthAmendment}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="message-card-footer">
        <div className="message-card-download-container" onClick={handleDownload}>
          <img src={downloadIcon} alt="Download" />
          <span>Download Table</span>
        </div>
        <img src={retryIcon} alt="Retry" className="message-card-retry-icon" onClick={handleRetry} />
      </div>
    </div>
  );
};

export default ComparisonTable;
