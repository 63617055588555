import React, { useState, useEffect } from 'react';
import './DocketUpload.css';
import DataTable from '@Shared/DataTable/DataTable';
import ActionMenu from '@Shared/ActionMenu/ActionMenu';
import actionIcon from '@assets/images/atticus/icons/more-line.svg';
import searchIcon from '@icons/search-gray-icon.svg';
import { useOutletContext } from 'react-router-dom';
import { FaRegTrashAlt } from 'react-icons/fa';
import { GrDocumentDownload } from 'react-icons/gr';
import Dropdown from '@Shared/Dropdown/Dropdown';
import { useParams, Link } from 'react-router-dom';
import { retrieveCaseTimeline } from '@lib/apis/pacer-api';
import { formatMMDDYYYY } from '@lib/utils/dateFormatHelpers';
import Docket from '@Shared/DocketModal/Docket';
import Loader from '@Shared/Loader/Loader';
import { getCase } from '@lib/apis/resources/case';
import Button from '@Shared/Button/Button';

const Options = [
  {
    text: 'All Filings',
  },
  {
    text: 'Cases',
  },
];

const DocketUpload = () => {
  const { selectedCase, setSelectedCase } = useOutletContext();
  const [docketData, setDocketData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(Options[0].text);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const handleFilterChange = (option) => {
    setSelectedFilter(option.text);
  };

  useEffect(() => {
    const fetchDocketData = async () => {
      const result = await retrieveCaseTimeline(id);
      if (result) {
        const transformedDocuments = Object.values(result.data)
          .map((entry) => {
            return entry.docket_entry.recap_documents.map((recapDoc) => ({
              date: formatMMDDYYYY(entry.date_filed.split('T')[0]),
              entered: formatMMDDYYYY(entry.date_created.split('T')[0]),
              filing: entry.description,
              url: entry.resource_uri,
              title: recapDoc?.description,
            }));
          })
          .flat();

        setDocketData(transformedDocuments);
      } else {
        console.error('Error in result data:', result.data);
      }
    };

    fetchDocketData();
    setLoading(false);
  }, [id]);

  const menuItems = [
    {
      label: 'Download document',
      labelColor: '#344054',
      icon: <GrDocumentDownload />,
      onClick: () => console.log('Download clicked'),
    },
    {
      label: 'Delete document',
      labelColor: '#F2295B',
      icon: <FaRegTrashAlt style={{ color: 'red' }} />,
      onClick: () => console.log('Delete clicked'),
    },
  ];

  const handleRowClick = (row, index) => {
    setSelectedRow(row);
    setSelectedRowId(index);
  };

  const renderRow = (row, index) => (
    <>
      <td
        className="table-data-docket-upload table-data-date-entered"
        onClick={() => handleRowClick(row, index)}
      >
        {row.date}
      </td>
      <td
        className="table-data-docket-upload"
        onClick={() => handleRowClick(row, index)}
      >
        {row.filing}
      </td>
      <td className="table-data-action-menu-button">
        <ActionMenu icon={actionIcon} items={menuItems} />
      </td>
    </>
  );

  return (
    <div className="docket-upload-page-main-container">
      <h4 className="docket-upload-page-main-title">Docket Upload</h4>
      {selectedCase && docketData[selectedRowId] && (
        <Docket
          selectedCase={selectedCase}
          selectedRow={docketData[selectedRowId]}
          onSkip={() =>
            setSelectedRowId((prev) =>
              Math.min(prev + 1, docketData.length - 1)
            )
          }
        />
      )}
      <div className="docket-upload-page-data-table-main white-background rounded-border ">
        <div className="docket-upload-page-title-section">
          <h5>All Filings</h5>

          <div className="docket-upload-page-dropdown-content">
            <div className="search-input-container">
              <img src={searchIcon} alt="Search Icon" className="search-icon" />
              <input
                type="text"
                placeholder="Search..."
                className="search-input"
              />
            </div>
            <div>
              <Dropdown
                options={Options.map((option) => option.text)}
                selectedOption={selectedFilter}
                onOptionSelect={handleFilterChange}
                placeholder="All Filings"
                isFilter
              />
            </div>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <DataTable
            headings={['Date', 'Filing', 'Action']}
            data={docketData}
            selectedRowId={selectedRowId}
            renderRow={renderRow}
            isPagination={false}
          />
        )}
      </div>
      <div className="finish-uploading-documents-container">
        <Link to={`/dashboard/cases/${id}/overview`}>
          <Button className="button--primary finish-uploading-documents">
            Finish Uploading Documents
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default DocketUpload;
