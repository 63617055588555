import React, { useState, useRef, useEffect } from 'react';
import './Profile.css';
import Header from '../../Shared/Header/Header';
import RoleSelection from '../Shared/RoleSelection/RoleSelection';
import MessageCard from '../Shared/MessageCard/MessageCard';
import { profileGeneratedData, profileRolesData } from './profileData';
import RoleDetailsCard from '../Shared/RoleDetailsCard/RoleDetailsCard';

const Profile = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const [name, setName] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const selectedRoleMessageRef = useRef(null);
  const generatedDataRef = useRef(null);

  const handleRoleChange = (roleId) => {
    setSelectedRole((prev) => (prev === roleId ? null : roleId));
    setName('');
    setIsGenerating(false);
  };

  const handleContinue = (name) => {
    setName(name);
    setIsGenerating(true);
    console.log(`Name entered for ${selectedRoleData.title}:`, name);

    setTimeout(() => {
      setIsGenerating(false);
    }, 1000);
  };

  const handleRetry = () => {
    console.log('retry button clicked');
  };

  const selectedRoleData = profileRolesData.find((role) => role.id === selectedRole);

  useEffect(() => {
    if (selectedRoleData) {
      selectedRoleMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedRoleData]);

  useEffect(() => {
    if (name && !isGenerating) {
      generatedDataRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [name, isGenerating]);

  return (
    <div className="chat-profile-container">
      <Header />
      <div className="chat-profile-content">
        <div className="chat-profile-time">Today, 09:00 AM</div>
        <MessageCard text="Welcome to the Develop a Profile section! I can help you create a detailed profile of a party involved in your case, such as a defendant, witness, or expert. Who would you like to develop a profile for today?" />
        <RoleSelection roles={profileRolesData} selectedRole={selectedRole} onRoleChange={handleRoleChange} />

        {selectedRoleData && (
          <>
            <div ref={selectedRoleMessageRef}>
              <MessageCard
                text={`You have selected ${selectedRoleData.title}. Please provide the name of the ${selectedRoleData.title} for whom you want to generate a profile.`}
              />
            </div>
            <RoleDetailsCard title={selectedRoleData.title} onContinue={handleContinue} />

            {name && isGenerating && <MessageCard text={`Profile generation in progress for ${name}. Please hold on while I gather the relevant details...`} />}

            {name && !isGenerating && (
              <div ref={generatedDataRef}>
                <MessageCard text={profileGeneratedData[0]?.heading} generatedData={profileGeneratedData[0]?.sections} handleRetry={handleRetry} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
