import React from 'react';
import './GroupAvatar.css';
import { AvatarGroup, Avatar } from '@chakra-ui/react';

const GroupAvatar = ({ legalTeam, maxAvatars = 5 }) => {

    const totalMembers = legalTeam?.length || 0;
    const displayedMembers = legalTeam?.slice(0, maxAvatars - 1) || [];
    const remainingMembers = totalMembers > maxAvatars ? totalMembers - (maxAvatars - 1) : 0;

    return (
        <>

            <AvatarGroup size='md' max={maxAvatars}>
                {
                    displayedMembers.map((member, index) => (
                        <Avatar
                            className='custom-case-team-member-image'
                            key={index}
                            name={member?.name}
                            src={member?.image}
                        />
                    ))
                }
                {remainingMembers > 0 && (
                    <Avatar className=' custom-case-team-member-image custom-case-team-member-image-extra' name='+'>
                        {remainingMembers}
                    </Avatar>
                )}
            </AvatarGroup>
        </>
    );
};

export default GroupAvatar;
