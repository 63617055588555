import React, { useState } from 'react';
import './Analyze.css';
import Header from '../../Shared/Header/Header';
import FileUpload from '../Shared/FileUpload/FileUpload';
import SelectedFile from '../Shared/SelectedFile/SelectedFile';
import MessageCard from '../Shared/MessageCard/MessageCard';
import RoleSelection from '../Shared/RoleSelection/RoleSelection';
import { analyzeData, analyzeGeneratedData } from './analyzeData';
import Button from '@Shared/Button/Button';
import AnalyzeMessageCard from './AnalyzeMessageCard/AnalyzeMessageCard';

const Analyze = () => {
  const [file, setFile] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [generatedData, setGeneratedData] = useState(null);

  const handleDropFile = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const handleRoleChange = (roleId) => {
    setSelectedRole((prev) => (prev === roleId ? null : roleId));
  };

  const handleDelete = () => {
    setFile(null);
    setSelectedRole(null);
    setGeneratedData(null);
  };

  const handleGenerateTable = () => {
    const data = analyzeGeneratedData;
    setGeneratedData(data);
  };

  return (
    <div className="chat-profile-container">
      <Header />
      <div className="chat-profile-content">
        <div className="chat-profile-time">Today, 09:00 AM</div>
        <div className="analyze-chat-content">
          <div className="analyze-top-heading-container">
            <h6>Analyze</h6>
            <p>Please upload the document you wish to analyze.</p>
          </div>

          {!file ? <FileUpload onDrop={handleDropFile} height="88px" isDragDropText={true} /> : <SelectedFile fileName={file.name} onDelete={handleDelete} />}

          {file && !generatedData && (
            <>
              <MessageCard text="Please select the issues you would like to analyze from the following list." />
              <RoleSelection roles={analyzeData} selectedRole={selectedRole} onRoleChange={handleRoleChange} isAnalyze />
              <div className="text-center mt-3">
                <Button className="button--primary white-text-color" onClick={handleGenerateTable}>
                  Analyze
                </Button>
              </div>
            </>
          )}

          {generatedData && <AnalyzeMessageCard generatedData={generatedData} handleRetry={handleDelete} />}
        </div>
      </div>
    </div>
  );
};

export default Analyze;
