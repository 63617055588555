import React from 'react';
import './TimelineItem.css';
import documentGreenIcon from '@icons/document-green-icon.svg';
import documentRedIcon from '@icons/document-red-icon.svg';
import judgeDecisionIcon from '@icons/judge-black-icon.svg';
import DocumentItem from '../DocumentItem/DocumentItem';

const TimelineItem = ({ date, role, documents }) => {
    let iconSrc;

    if (role.includes('Defense Attorney')) {
        iconSrc = documentGreenIcon;
    } else if (role.includes('Prosecutor')) {
        iconSrc = documentRedIcon;
    } else if (role.includes('Judge Decision')) {
        iconSrc = judgeDecisionIcon;
    } else {
        iconSrc = documentGreenIcon;
    }

    return (
        <div className="timeline-item">
            <div className="timeline-icon-container">
                <img src={iconSrc} alt="Document Icon" className="timeline-icon" />
            </div>
            <div className="timeline-content">
                <div className="timeline-header">
                    <span className="timeline-date">{date}</span>
                    <span className={`timeline-role `}>{role}</span>
                </div>
                <div className="document-item-card document-list">
                    {documents.map((doc, index) => (
                        <DocumentItem key={index} title={doc.text} role={role} actionType={doc?.action_type} summary={doc?.summary} uploadFileStatus={doc?.file_upload_status} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TimelineItem;
