import React, { useContext } from 'react';
import GenerateMotionModalPage from './GenerateMotionModalPage';
import SharedButton from '@Shared/Button/Button';
import GenerateMotionContext from '../../GenerateMotionContext';
import './MotionModalPages.css';

const MotionModalPage1 = ({ pageIndex }) => {
  const { setCurrentPageIndex, setFormData, formData } = useContext(
    GenerateMotionContext
  );

  const handleChange = (e) => {
    setFormData({ ...formData, query: e.target.value });
  };

  return (
    <GenerateMotionModalPage
      className="motion-modal-page-1"
      title="Why are you filing this motion?"
      pageIndex={pageIndex}
    >
      <textarea
        value={formData.query}
        onChange={handleChange}
        placeholder="Describe motion's purpose"
      ></textarea>
      <SharedButton
        onClick={() => setCurrentPageIndex(pageIndex + 1)}
        className="button--primary"
      >
        Continue
      </SharedButton>
    </GenerateMotionModalPage>
  );
};

export default MotionModalPage1;
