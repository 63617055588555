import React, { useState } from 'react';
import './LegalTeamsIndex.css';
import LegalTeamMemberCard from './LegalTeamMemberCard/LegalTeamMemberCard';
import { legalTeamMembersData } from './legalTeamData';
import addIcon from '@icons/add-white-icon.svg';
import searchIcon from '@icons/search-gray-icon.svg';
import Dropdown from '@Shared/Dropdown/Dropdown';

const roles = ['All Members', 'Admin', 'Members'];

const LegalTeamsIndex = () => {
  const [selectedRole, setSelectedRole] = useState(roles[0]);

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <div className="dashboard-legal-team-container">
      <div className="legal-team-dashboard-sub-header-container">
        <h1 className="dashboard-legal-team-heading">
          Federal Criminal{' '}
          <span className="dashboard-legal-team-heading-gray">
            Legal Team ({legalTeamMembersData?.length} members)
          </span>{' '}
        </h1>
        <div>
          <div className="search-container">
            <div className="search-input-container">
              <img src={searchIcon} alt="Search Icon" className="search-icon" />
              <input
                type="text"
                placeholder="Search member..."
                className="search-input"
              />
            </div>
            <Dropdown
              options={roles}
              selectedOption={selectedRole}
              onOptionSelect={handleRoleSelect}
              placeholder="Select Role"
              isFilter={true}
            />
          </div>
        </div>
      </div>
      <div className="legal-team-member-cards-container">
        {legalTeamMembersData?.map((data, index) => (
          <LegalTeamMemberCard
            key={index}
            image={data.image}
            name={data.name}
            role={data.role}
            title={data.title}
            id={data.id}
          />
        ))}
        <div className="legal-add-team-member-card">
          <img src={addIcon} alt="" />
          <p className="add-team-member-card-text">Add Team Member</p>
        </div>
      </div>
    </div>
  );
};

export default LegalTeamsIndex;
