import { combineReducers } from "redux";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import legalTeamReducer from "./legalTeamReducer";
import casesReducer from "./casesReducer";

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  legalTeam: legalTeamReducer,
  cases: casesReducer,
});

export default rootReducer;
