import React from "react";
import { Link } from "react-router-dom";

import './UserHeadshot.css';

const UserHeadshot = ({src, alt, link, style}) => {
  return (
    <div className="user-headshot" style={style}>
      <Link to={link}>
        <img src={src} alt={alt} />
      </Link>
    </div>
  )
}

export default UserHeadshot;
