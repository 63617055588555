import React, { useState, useEffect, useRef } from 'react';
import './Motions.css';
import Button from '@Shared/Button/Button';
import MotionOptionsSelect from './MotionOptionsSelect';
import { ReactComponent as DownloadFile } from '@icons/download-file.svg';
import GenerateMotionModal from './GenerateMotionModal/GenerateMotionModal';
import GenerateMotionProvider from './GenerateMotionProvider';
import GenerateMotionButton from './GenerateMotionButton';
import MotionTypeButtons from './MotionTypeButtons';
import { generateDocketRecommendations } from '@lib/apis/pacer-api';
import LoaderSpinner from '@Shared/LoaderSpinner/LoaderSpinner';
import { useNavigate } from 'react-router-dom';

const Motions = ({ setIsRecommendationsFetched }) => {
  const [motionTypes, setMotionTypes] = useState([]);
  const loadingRef = useRef(false);
  const [loading, setLoading] = useState(false);

  const recentMotions = [{ name: 'Motion to Extend Time' }, { name: 'Motion to Suppress Evidence' }];

  const id = '84083408-a051-70d1-f910-749d49645793';
  const caseId = '67891889';
  const appId = '1';

  useEffect(() => {
    const fetchMotion = async () => {
      try {
        if (loadingRef.current) return;
        loadingRef.current = true;
        setLoading(true);

        const response = await generateDocketRecommendations(id, caseId, appId);
        if (response.success && response.data.length > 0) {
          const updatedMotionTypes = response.data
            .map((motion) => ({
              name: motion?.name,
              value: motion?.value,
            }))
            .filter((motion) => motion.name !== null);

          localStorage.setItem('motionTypes', JSON.stringify(updatedMotionTypes));
          localStorage.setItem('motionTypesFetched', 'true');
          setMotionTypes(updatedMotionTypes);
          setLoading(false);
          setIsRecommendationsFetched(true);
        } else {
          setLoading(false);
          fetchMotion();
        }
      } catch (error) {
        console.error('Error fetching motion types: ', error);
      } finally {
        loadingRef.current = false;
      }
    };

    const savedMotions = JSON.parse(localStorage.getItem('motionTypes'));
    const isFetched = localStorage.getItem('motionTypesFetched');

    if (savedMotions && savedMotions.length > 0) {
      setMotionTypes(savedMotions);
      setLoading(false);
    } else if (!isFetched) {
      fetchMotion();
    }
  }, [id, caseId, appId]);

  return (
    <GenerateMotionProvider>
      <div className="motion-container">
        <h3 className="cases-motion-title">Motions</h3>
        <div className="motion-recommendations-container">
          <h4>Atticus Recommends</h4>
          {loading ? (
            <>
              <LoaderSpinner color="#2dc008" />
            </>
          ) : (
            <>
              <MotionTypeButtons motionTypes={motionTypes} />
              <MotionOptionsSelect />
              <GenerateMotionButton />
            </>
          )}
        </div>
        <div className="recent-motions-container">
          <h4>Recently Used Motions</h4>
          {recentMotions.map((motion, index) => (
            <div className="recent-motion" key={index}>
              <DownloadFile /> {motion.name}
            </div>
          ))}
        </div>
        <GenerateMotionModal />
      </div>
    </GenerateMotionProvider>
  );
};

export default Motions;
