export const meetAtticusData = [
  {
    heading: "AI-Powered Motion & Brief Drafting",
    description:
      "Atticus is trained on thousands of examples of the most common kinds of Federal defense motions. At the touch of a button, he takes your clients’: case dockets, documents, information, discovery, and then produces a motion of your choosing according to your needs. You can select a motion type or use one of his recommendations. Within seconds, have a fully drafted motion as a starting point.",
    firstText: "Motion to Suppress Post Arrest Statements ",
    secondText: "Motion to Compel Discovery",
  },

  {
    heading: "AI-Powered Motion & Brief Drafting",
    description:
      "Atticus is trained on thousands of examples of the most common kinds of Federal defense motions. At the touch of a button, he takes your clients’: case dockets, documents, information, discovery, and then produces a motion of your choosing according to your needs. You can select a motion type or use one of his recommendations. Within seconds, have a fully drafted motion as a starting point.",
    firstText: "Motion to Suppress Post Arrest Statements ",
    secondText: "Motion to Compel Discovery",
  },
  {
    heading: "AI-Powered Motion & Brief Drafting",
    description:
      "Atticus is trained on thousands of examples of the most common kinds of Federal defense motions. At the touch of a button, he takes your clients’: case dockets, documents, information, discovery, and then produces a motion of your choosing according to your needs. You can select a motion type or use one of his recommendations. Within seconds, have a fully drafted motion as a starting point.",
    firstText: "Motion to Suppress Post Arrest Statements ",
    secondText: "Motion to Compel Discovery",
  },
  {
    heading: "AI-Powered Motion & Brief Drafting",
    description:
      "Atticus is trained on thousands of examples of the most common kinds of Federal defense motions. At the touch of a button, he takes your clients’: case dockets, documents, information, discovery, and then produces a motion of your choosing according to your needs. You can select a motion type or use one of his recommendations. Within seconds, have a fully drafted motion as a starting point.",
    firstText: "Motion to Suppress Post Arrest Statements ",
    secondText: "Motion to Compel Discovery",
  },

  {
    heading: "AI-Powered Motion & Brief Drafting",
    description:
      "Atticus is trained on thousands of examples of the most common kinds of Federal defense motions. At the touch of a button, he takes your clients’: case dockets, documents, information, discovery, and then produces a motion of your choosing according to your needs. You can select a motion type or use one of his recommendations. Within seconds, have a fully drafted motion as a starting point.",
    firstText: "Motion to Suppress Post Arrest Statements ",
    secondText: "Motion to Compel Discovery",
  },
  {
    heading: "AI-Powered Motion & Brief Drafting",
    description:
      "Atticus is trained on thousands of examples of the most common kinds of Federal defense motions. At the touch of a button, he takes your clients’: case dockets, documents, information, discovery, and then produces a motion of your choosing according to your needs. You can select a motion type or use one of his recommendations. Within seconds, have a fully drafted motion as a starting point.",
    firstText: "Motion to Suppress Post Arrest Statements ",
    secondText: "Motion to Compel Discovery",
  },
];
