/* eslint-disable no-constant-condition */
import axios from 'axios';
import { createPacerData } from '../../graphql4/mutations';
import cases from '../mocks/cases';
import { generateClient } from './api-client'; // Ensure the path is correct
import { First } from 'react-bootstrap/esm/PageItem';
import motionDictionary from '@lib/utils/motionDictionary.js';

// const BASE_URL = 'https://juristaiapi.wrmlabs.com/user/search';

// Search by ID function
export const searchById = async (id, token) => {
  // TODO: Remove this mock data when the API is ready
  if (false) {
    // Change to `false` to bypass mock data when the API is ready
    return [cases[0]];
  }

  try {
    const response = await axios.get(`${BASE_URL}?id=${id}&token=${token}`, {
      maxRedirects: 5,
    });
    const data = await response.data;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Advanced search function
export const advancedSearch = async (searchParams) => {
  try {
    const LAMBDA_URL = 'https://um8055uehb.execute-api.us-east-1.amazonaws.com/devv1/CLIDDocketSearch';
    const body = {};
    const headers = {
      'Content-Type': 'application/json',
    };

    // Loop through each key-value pair in params
    for (const [key, value] of Object.entries(searchParams)) {
      // Add the key-value pairs to the body
      body[key] = value;
    }

    // Format body as a string according to the API requirements
    const stringBody = { body: JSON.stringify(body) };
    const response = await axios.post(LAMBDA_URL, stringBody, { headers });

    const jsonData = JSON.parse(response.data.body);
    return { success: true, data: jsonData };
  } catch {
    return { success: false, data: 'There was a problem with your search.' };
  }
};

export const getCaseMetadataFromDocketId = async (docketId) => {
  try {
    const LAMBDA_URL = 'https://qcacbzrrf2.execute-api.us-east-1.amazonaws.com/devv1/CaseMetadataDocketIDCL';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = { body: '{"docket_id": " ' + docketId + '"}' };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    const jsonData = JSON.parse(response.data.body);
    return { success: true, data: jsonData };
  } catch (error) {
    return { success: false, data: 'There was a problem with your search.' };
  }
};

export const addCaseS3creationJS = async (docketId) => {
  try {
    const LAMBDA_URL = 'https://2ko4or4dr3.execute-api.us-east-1.amazonaws.com/devv1/createbucket';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = { body: '{"case_id": "' + docketId + '"}' };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    const data = JSON.parse(response.data.body);
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const getDocumentsFromDocketId = async (docketId) => {
  try {
    const LAMBDA_URL = 'https://eefyhgz6sa.execute-api.us-east-1.amazonaws.com/devv1/doc-txt-rtrvr';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = { body: '{"docket_id": "' + docketId + '"}' };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    const data = response.data.body;
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your search.' };
  }
};

export const updateCaseInDnmoDBCaseTable = async (docketId) => {
  try {
    const LAMBDA_URL = 'https://bidecqei1i.execute-api.us-east-1.amazonaws.com/devv1/casetableupdate';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = { body: '{"docket_id": "' + docketId + '"}' };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    const data = JSON.parse(response.data.body);
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const retrieveCaseTimeline = async (docketId) => {
  try {
    const LAMBDA_URL = 'https://tgr0b1064e.execute-api.us-east-1.amazonaws.com/devv1/DocketMetadataRetriever';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = { body: '{"docket_id": "' + docketId + '"}' };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    const data = response.data.body;
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your search.' };
  }
};

export const uploadCaseDocument = async (userId, caseId) => {
  try {
    const LAMBDA_URL = 'https://7z7erimcph.execute-api.us-east-1.amazonaws.com/devv1/upload-docs';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = '{ "userId": "' + userId + '", "caseId": "' + caseId + '" }';
    const response = await axios.post(LAMBDA_URL, body, { headers });
    const data = JSON.parse(response.data.body);
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const createS3BucketForUserAndCase = async (userId, caseId) => {
  try {
    const LAMBDA_URL = 'https://qwsmd1hys0.execute-api.us-east-1.amazonaws.com/devv1/user-case-s3';
    const headers = {
      'Content-Type': 'application/json',
    };
    const lambdaFunctionArn = 'arn:aws:lambda:us-east-1:730335261767:function:hallucination_detection';
    // const body = "{ \"userId\": \"" + userId + "\", \"caseId\": \"" + caseId + "\" + \"lambda_function_arn\": \"" + lambdaFunctionArn + "\" }";
    const body = {
      userId: userId,
      caseId: caseId,
      lambda_function_arn: lambdaFunctionArn,
    };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    const data = response.data;
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const updateActivityLogFile = async (bucketName) => {
  try {
    const LAMBDA_URL = 'https://18jazgkwdg.execute-api.us-east-1.amazonaws.com/devv1/bucket-log';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = { body: '{ "bucket_name": "84083408-a051-70d1-f910-749d49645793-67891889" }' };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    if (response.data.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action.' };
    }
    const data = response.data.body;
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

// TODO: Hook this up to the api
export const summarizeDocument = async (caseId, userId, docketEntryId) => {
  try {
    const LAMBDA_URL = 'MOCK';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      caseId: caseId,
      userId: userId,
      docketEntryId: docketEntryId,
    };

    // const response = await axios.post(LAMBDA_URL, body, { headers });
    // const data = response.data;
    const data =
      'The criminal complaint against Donald Trump alleges that he conspired to defraud the United States by interfering with the lawful functions of the federal government. The charges include attempts to obstruct the certification of the 2020 presidential election results and to incite an insurrection at the Capitol on January 6, 2021.';
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const generateDocketRecommendations = async (userId, caseId, appId = 1) => {
  try {
    const LAMBDA_URL =
      'https://dy8hfa9zai.execute-api.us-east-1.amazonaws.com/devv1/crim-recom?userId=84083408-a051-70d1-f910-749d49645793&caseId=67891889&appId=1';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      body: `{"userId": "${userId}", "caseId": "${caseId}", "appId": "${appId}"}`,
    };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    if (response.data.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action.' };
    }

    const responseData = response.data.body;
    const jsonData = JSON.parse(responseData);

    const recommendedMotionsString = jsonData.recommended_motions.replace(/```json\n|\n```/g, '');
    const docketRecommendations = JSON.parse(recommendedMotionsString);
    const cleanRecommendations = cleanMotions(docketRecommendations.recommended_motions);

    const motion1 = cleanRecommendations.Motion1;
    const motion2 = cleanRecommendations.Motion2;
    const motion3 = cleanRecommendations.Motion3;
    const data = [
      {
        value: motion1,
        name: motionDictionary[motion1],
      },
      {
        value: motion2,
        name: motionDictionary[motion2],
      },
      {
        value: motion3,
        name: motionDictionary[motion3],
      },
    ];
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

const cleanMotions = (motions) => {
  if (!motions) {
    console.log('Motions are undefined or null');
    return {};
  }

  return {
    Motion1: motions.Motion1,
    Motion2: motions.Motion2,
    Motion3: motions.Motion3,
  };
};

export const summarizeCase = async (userId, caseId, appId) => {
  try {
    const LAMBDA_URL = 'https://33cfxkgfzg.execute-api.us-east-1.amazonaws.com/devv1/summarize-case';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      body: `{"userId": "${userId}", "caseId": "${caseId}", "appId": "${appId}"}`,
    };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    if (response.data.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action.' };
    }
    const data = JSON.parse(response.data.body);
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

export const queryPrecedents = async (userId, caseId, documentName, motionId) => {
  try {
    const LAMBDA_URL = 'https://5y3pmv39nf.execute-api.us-east-1.amazonaws.com/devv1/prec-query';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = {
      userId: userId,
      caseId: caseId,
      documentName: documentName,
      motionId: motionId,
    };
    const response = await axios.post(LAMBDA_URL, body, { headers });
    if (response.data.statusCode !== 200) {
      return { success: false, data: 'There was a problem with your action.' };
    }
    const data = JSON.parse(response.data.body);
    return { success: true, data: data };
  } catch (error) {
    return { success: false, data: 'There was a problem with your action.' };
  }
};

// Add case to PACER
export const addCase = async ({
  courtId,
  caseId,
  caseYear,
  caseNumber,
  caseOffice,
  caseType,
  caseTitle,
  dateFiled,
  dateTermed,
  dateDischarged,
  bankruptcyChapter,
  dispositionMethod,
  jointBankruptcyFlag,
  jurisdictionType,
  effectiveDateClosed,
  caseLink,
  caseNumberFull,
}) => {
  try {
    const client = generateClient(); // Ensure this function is correctly defined and imported
    const newPacerData = await client.graphql({
      query: createPacerData,
      variables: {
        input: {
          courtId,
          caseId,
          caseYear,
          caseNumber,
          caseOffice,
          caseType,
          caseTitle,
          dateFiled,
          dateTermed,
          dateDischarged,
          bankruptcyChapter,
          dispositionMethod,
          jointBankruptcyFlag,
          jurisdictionType,
          effectiveDateClosed,
          caseLink,
          caseNumberFull,
          status: true,
        },
      },
    });

    console.log('newPacerData', newPacerData);
    return newPacerData;
  } catch (error) {
    console.log(error);
    return error;
  }
};
