import React, { useEffect, useState } from 'react';
import './AddNewCase.css';
import TextField from '@Shared/TextField/TextField';
import Button from '@Shared/Button/Button';
import DatePicker from '@Shared/DatePicker/DatePicker';
import { toast } from 'react-toastify';
import { formatSlashDate, parseDate } from '@lib/utils/dateFormatHelpers';

const AddNewCase = ({ onClose, onSearch, initialValues }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    caseNumber: '',
    court: '',
    dateFiled: '',
    dateClosed: '',
  });

  useEffect(() => {
    if (initialValues) {
      setFormData({
        firstName: initialValues.firstName || '',
        lastName: initialValues.lastName || '',
        caseNumber: initialValues.caseNumber || '',
        court: initialValues.court || '',
        dateFiled: initialValues.dateFiled || '',
        dateClosed: initialValues.dateClosed || '',
      });
    }
  }, [initialValues]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (name, date) => {
    setFormData({ ...formData, [name]: formatSlashDate(date) });
  };

  const handleAdvancedSearch = () => {
    const isEmpty = Object.values(formData).every((value) => !value);
    if (isEmpty) {
      toast.info('Please fill in at least one field to search.');
      return;
    }
    onSearch(formData);
    handleReset();
  };

  const handleReset = () => {
    setFormData({
      firstName: '',
      lastName: '',
      caseNumber: '',
      court: '',
      dateFiled: '',
      dateClosed: '',
    });
    onClose();
  };

  return (
    <div className="add-case-modal">
      <div className="case-form-group">
        <label htmlFor="firstName">First Name</label>
        <TextField placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} className="case-form-group-input" />
      </div>
      <div className="case-form-group">
        <label htmlFor="lastName">Last Name</label>
        <TextField placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} className="case-form-group-input" />
      </div>
      <div className="case-form-group">
        <label htmlFor="caseNumber">Case Number Full</label>
        <TextField placeholder="Case Number Full" name="caseNumber" value={formData.caseNumber} onChange={handleChange} className="case-form-group-input" />
      </div>
      <div className="case-form-group">
        <label htmlFor="court">Court</label>
        <TextField placeholder="Court" name="court" value={formData.court} onChange={handleChange} className="case-form-group-input" />
      </div>
      <div className="case-form-date-picker-group">
        <div className="case-form-group">
          <label htmlFor="dateFiled">Date Filed</label>
          <DatePicker
            placeholder="Date Filed"
            name="dateFiled"
            value={parseDate(formData.dateFiled)}
            selected={parseDate(formData.dateFiled)}
            onChange={(date) => handleDateChange('dateFiled', date)}
          />
        </div>
        <div className="case-form-group">
          <label htmlFor="dateClosed">Date Closed</label>
          <DatePicker
            placeholder="Date Closed"
            name="dateClosed"
            value={parseDate(formData.dateClosed)}
            selected={parseDate(formData.dateClosed)}
            onChange={(date) => handleDateChange('dateClosed', date)}
          />
        </div>
      </div>
      <Button className="button--primary button--full-width" onClick={handleAdvancedSearch}>
        Search
      </Button>
      <Button className="cancel-btn" onClick={handleReset}>
        Cancel
      </Button>
    </div>
  );
};

export default AddNewCase;
