const values = {
  appearanceBondForDefendant_FormNoCrr46_20: 'Appearance Bond for Defendant',
  appellateCourtReleaseOnBailPendingAppeal_Crr46_31: 'Appellate Court Release on Bail Pending Appeal',
  attorney_sAffidavitInSupportOfMotionForTransfer_Crr21_11: "Attorney's Affidavit in Support of Motion for Transfer",
  authorizationToReleasePrivateInformation_Crr32_4: 'Authorization to Release Private Information',
  billOfParticulars_Crr7_5: 'Bill of Particulars',
  compelDiscoveryOrProhibitUseOfEvidenceFedRCrimP16D2: 'Motion to Compel Discovery or Prohibit Use of Evidence',
  compelProsecutorToCeaseAbusingGrandJurySecrecy_Crr6_40:
    'Motion to Compel Prosecutor to Cease Abusing Grand Jury Secrecy',
  defaultJudgment: 'Default Judgment',
  dismissCountOfIndictmentForDefectiveStatement_Crr12_7: 'Dismissal of Indictment Count for Defective Statement',
  dismissCountOfIndictmentForInadequateNoticeOfCharges_NoCrr7_32:
    'Dismissal of Indictment Count for Inadequate Notice of Charges',
  dismissForFailureToStateOffense_Crr7_34: 'Dismissal for Failure to State an Offense',
  dismissIndictment_Crr6_23: 'Motion to Dismiss Indictment',
  dismissIndictmentDueToProsecutorialAbuseOfGrandJuryProcess_Crr6_21:
    'Dismissal of Indictment Due to Prosecutorial Abuse of Grand Jury Process',
  dismissIndictmentFalseEvidencePresentedToGrandJury_Crr6_23:
    'Dismissal of Indictment for False Evidence Presented to Grand Jury',
  'dismissIndictment-SystematicExclusionOfClassFromGrandJury_Crr12_8':
    'Dismissal of Indictment for Systematic Exclusion of Class from Grand Jury',
  districtCourtReviewOfMagistrate_SReleaseOrder_Crr46_30: "District Court Review of Magistrate's Release Order",
  extensionOfTimeAfterExpiry_Crr45_2: 'Extension of Time after Expiry',
  extensionOfTimeBeforeExpiry_Crr45_1: 'Extension of Time before Expiry',
  formNoCrr32_10MotionByDefendantToInspectPresentenceInvestigationReport:
    'Motion to Inspect Presentence Investigation Report',
  initialInterrogatoriesForDefenseAttorneysOfRecord: 'Initial Interrogatories for Defense Attorneys of Record',
  juryInstructionIdentificationTestimony_Crr30_41: 'Jury Instruction for Identification Testimony',
  juryInstructions_WillfulBlindness_AsAWayOfSatisfying_Knowingly__FormNoCrr30_71:
    'Jury Instructions on Willful Blindness as Knowledge',
  'juryInstructions-ActualAndConstructivePossession_FormNoCrr30_72':
    'Jury Instructions on Actual and Constructive Possession',
  'juryInstructions-JointPossession_Crr30_73': 'Jury Instructions on Joint Possession',
  leaveToPleadNoloContendere_Crr11_1: 'Leave to Plead Nolo Contendere',
  listofmotions: 'List of Motions',
  memorandumInSupportBillOfParticulars_Crr7_57: 'Memorandum in Support of Bill of Particulars',
  memorandumInSupportOfDefaultJudgment: 'Memorandum in Support of Default Judgment',
  memorandumInSupportOfDismissForFailureToStateOffense_Crr7_35:
    'Memorandum in Support of Dismissal for Failure to State Offense',
  memorandumSupportOfMotionToStrikeSurplusage_Crr7_45: 'Memorandum in Support of Motion to Strike Surplusage',
  motionForSuppressionOfPostArrestStatementOfDefendant_FormNoCrr5_10:
    'Motion for Suppression of Post-Arrest Statement of Defendant',
  motionToCompelDisclosureOfRecordsOfElectronicSurveillanceAndForSuppressionHearing_Crr16_32:
    'Motion to Compel Disclosure of Records of Electronic Surveillance and for Suppression Hearing',
  motionToDismissCount: 'Motion to Dismiss Count',
  motionToDismissIndictmentDueToImproperSelectionOfGrandJuryForm_NoCrr6_20:
    'Motion to Dismiss Indictment Due to Improper Selection of Grand Jury',
  'motionToExpeditePre-ConferenceDiscovery': 'Motion to Expedite Pre-Conference Discovery',
  'motionToStrikeMatterAsSurplusage-NotRelevantAndPrejudicial_Crr742':
    'Motion to Strike Matter as Surplusage (Not Relevant and Prejudicial)',
  noticeOfTakingDeposition_Crr15_40: 'Notice of Taking Deposition',
  oppositionToExtensionOfTime: 'Opposition to Extension of Time',
  permitDefendantToTravelOutOfState_Crr46_36: 'Permit Defendant to Travel Out of State',
  permitInspectionOfGrandJuryRecord_Crr6_30: 'Permit Inspection of Grand Jury Record',
  pleaAgreementWithRecommendationForDownwardDeparture_Crr11_10:
    'Plea Agreement with Recommendation for Downward Departure',
  pleaWithdrawal_Crr32_4: 'Plea Withdrawal',
  productionOfRelevantGrandJuryMaterial_Crr6_31: 'Production of Relevant Grand Jury Material',
  rebuttalToCauseShown: 'Rebuttal to Cause Shown',
  reduceBail_Crr46_32: 'Motion to Reduce Bail',
  refundOfCashDeposit_Crr46_53: 'Refund of Cash Deposit',
  remandToConsiderMotionUnderFedRCrimP33: 'Remand to Consider Motion under Fed. R. Crim. P. 33',
  requestToClerkToEnterDefault: 'Request to Clerk to Enter Default',
  responseToOrderOfDetention_Crr46_2: 'Response to Order of Detention',
  reviewOfDenialOfReleasePendingAppeal_Crr46_53: 'Review of Denial of Release Pending Appeal',
  severanceOfCounts_Crr8_1: 'Severance of Counts',
  severanceOfDefendants_Crr8_10: 'Severance of Defendants',
  severanceOrToRedactStatementByCodefendant_Crr14_32: 'Motion for Severance or to Redact Statement by Codefendant',
  strikeMatterAsSurplusage_Crr7_44: 'Motion to Strike Matter as Surplusage',
  strikeMatterAsSurplusageAliases_Crr7_43: 'Motion to Strike Matter as Surplusage (Aliases)',
  strikeSurplusage_Crr7_43: 'Motion to Strike Surplusage',
  waiver_arraignment_plea: 'Waiver of Arraignment and Plea',
  'waiverOfHearingInDistrictOfArrest-GeneralForm_FormNoCrr51_20':
    'Waiver of Hearing in District of Arrest - General Form',
  waiverOfIndictment_Crr7_40: 'Waiver of Indictment',
  waiverOfPreliminaryHearing_Crr51_2: 'Waiver of Preliminary Hearing',
  waiverOfRightToAppointedCounsel_Crr44_1: 'Waiver of Right to Appointed Counsel',
  waiverOfRule551HearingsComplaintOrIndictment: 'Waiver of Rule 5.5.1 Hearings for Complaint or Indictment',
};

export default values;

export const motionOptions = Object.entries(values).map(([key, value]) => ({
  name: value,
  value: key,
}));
