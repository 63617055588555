import React, { useState, useRef, useEffect, useContext } from 'react';
import './Argument.css';
import Header from '../../Shared/Header/Header';
import RoleSelection from '../Shared/RoleSelection/RoleSelection';
import MessageCard from '../Shared/MessageCard/MessageCard';
import { argumentData } from './aurgumentData';
import ChatInputBox from '../../Shared/ChatInputBox/ChatInputBox';
import ChatContext from '../../ChatContext';
import ProcessingMessageBubble from '../../Research/ProcessingMessageBubble/ProcessingMessageBubble';
import Message from './Message/Message';

const targetChatType = 'argument';

const Argument = () => {
  const { waitingForResponse, messages, addResponseMessage } = useContext(ChatContext);
  const [selectedRole, setSelectedRole] = useState(null);
  const chatContentRef = useRef(null);

  const handleRoleChange = (roleId) => {
    setSelectedRole((prev) => (prev === roleId ? null : roleId));
  };

  const selectedRoleData = argumentData.find((role) => role.id === selectedRole);

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages, selectedRole]);

  useEffect(() => {
    if (selectedRoleData) {
      addResponseMessage(selectedRoleData?.question, targetChatType);
    }
  }, [selectedRoleData]);

  return (
    <div className="chat-profile-container">
      <Header />
      <div className="chat-profile-content" ref={chatContentRef}>
        <div className="chat-profile-time">Today, 09:00 AM</div>
        <MessageCard text="Welcome to the Argument section! I can help you develop arguments for your case, whether you’re supporting a claim or refuting the prosecution’s position. What would you like to work on today?" />
        <RoleSelection roles={argumentData} selectedRole={selectedRole} onRoleChange={handleRoleChange} />

        {messages
          .filter((message) => message?.type === targetChatType)
          .map((message) => (
            <React.Fragment key={message.id}>
              {typeof message.content === 'string' ? (
                <Message content={message.content} userIsAuthor={message.userIsAuthor} />
              ) : (
                <>
                  <Message content={message.content.heading} userIsAuthor={message.userIsAuthor} />
                  <MessageCard generatedData={message.content.sections} maxWidth="80%" isLogo={false} />
                </>
              )}
            </React.Fragment>
          ))}
      </div>
      {waitingForResponse && <ProcessingMessageBubble />}
      <ChatInputBox type={targetChatType} isDefaultSelected={selectedRoleData ? true : false} />
    </div>
  );
};

export default Argument;
