import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button } from '@chakra-ui/react';
import './ActionMenu.css';

const ActionMenu = ({ icon, items }) => {
    return (
        <Menu>
            <MenuButton
                as={Button}
                p={0}
                zIndex={0}
                variant="link"
                className="action-menu-button"
                _hover={{ backgroundColor: 'transparent' }}
            >
                <img src={icon} alt="Menu" className="action-menu-icon" />
            </MenuButton>
            <MenuList className="action-menu-list" zIndex={1}>
                {items.map((item, index) => (
                    <MenuItem
                        key={index}
                        icon={item.icon}
                        onClick={() => item.onClick(item)}
                        className="action-menu-item"
                        style={{ color: item?.labelColor }}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default ActionMenu;
