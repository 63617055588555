import { legalTeamTypes } from '../types';

const initialState = null;

const legalTeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case legalTeamTypes.CREATE_ONE:
      return action.payload;
    case legalTeamTypes.GET_ONE:
    case legalTeamTypes.SET_LEGAL_TEAM:
      return action.payload;
    case legalTeamTypes.UPDATE_ONE:
      return action.payload;
    default:
      return state;
  }
}

export default legalTeamReducer;
