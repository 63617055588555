import React from 'react';
import { motion } from 'framer-motion';
import logo from '@assets/images/atticus/icons/atticus_alone.svg';
import './Loader.css';

const Loader = () => {
  return (
    <div className="atticus-backdrop-container">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{
          repeat: Infinity,
          duration: 2.5,
          ease: 'linear',
        }}
        className="atticus-spinner-container"
      />
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 180, scale: 1 }}
        transition={{
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
        className="atticus-loader-container"
      />
      <img src={logo} alt="Logo" className="atticus-loader-logo-container" />
    </div>
  );
};

export default Loader;
